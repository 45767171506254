/* eslint-disable eqeqeq */
import React, { Suspense, lazy } from 'react';
import { withTranslation } from "react-i18next";
import "./assets/scss/style.scss";
import { Utilities } from './Utilities/Utilities';
const NoNetwork = lazy(()=>import('./views/NoNetwork/NoNetwork'));
const MyProvider = lazy(()=>import('./InitialSetup/MyProvider'));

if (process.env.REACT_APP_GTM_ID != '') {
  const TagManager = require('react-gtm-module');
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  }
  TagManager.initialize(tagManagerArgs)
}

// if (!window.ReactNativeWebView) {
//   let SessionLog = require("./helper/SessionLog").default;
//   const sessionlog = new SessionLog()
//   sessionlog.init()
// }

class App extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isOnline: true,
    };
    Utilities.setCpSession()
  };

  componentDidMount() {
    this.setState({ isOnline: navigator.onLine })
    this.disableLogs();
  }

  disableLogs = () => {
    try {
      if (process.env.NODE_ENV !== "development") {
        console.log = () => { };
        console.warn = () => { };
      }
    } catch (error) {
    }
  }

  checkISOnline = () => {
    return (
      <Suspense fallback={<div />} >{this.state.isOnline ? <MyProvider /> : <NoNetwork />}</Suspense>
    )
  }

  isValidDomain = () => {
    let urlDomain = window.location.origin;
    let urlDomainList = JSON.parse(process.env.REACT_APP_HOSTS);
    var isValid = false;

    if (urlDomainList.includes(urlDomain)) {
      isValid = true
    }
    return isValid;
  }

  render() {
    return (
      <>
        {
          !this.isValidDomain() ? <div className='no-network-container'>
            <div className='child-item'>
              <img className="img-fluid" alt='' src={require('./assets/img/Unauthorised.jpg')} />
            </div>
          </div>
            :
            this.checkISOnline()
        }
      </>
    );
  }
}

export default withTranslation()(App)
