import { Sports } from "../JsonFiles";
import { MALE, FEMALE } from "./AppLabels";
import ls from "local-storage";
import { _Map } from "../Utilities/Utilities";

export var OTPSIZE = 4;

export var IS_BRAND_ENABLE = process.env.REACT_APP_DEV_BY_VTECH == 1;
export var IS_SPORTS_HUB = false;
export var IS_PREDICTION = false;
export var IS_PICKEM = false;
export var IS_DFS = false;
export var IS_TOURNAMENT = false;
export var IS_MULTIGAME = false;
export var IS_OPEN_PREDICTOR = false;
export var IS_FPP_OPEN_PREDICTOR = false; // open predictor with fixed prize pool
export var SELECTED_GAMET = '';
export var TOAST = {};
export const CONTEST_UPCOMING = 0;
export const CONTEST_LIVE = 1;
export const CONTEST_COMPLETED = 2;
export const CONTESTS_LIST = -1;
export const BANNER_TYPE_REFER_FRIEND = 2;
export const BANNER_TYPE_DEPOSITE = 3;
export var DEFAULT_COUNTRY = 'in';
export var DEFAULT_COUNTRY_CODE = '91';
export var ONLY_SINGLE_COUNTRY = process.env.REACT_APP_ONLY_ONE_COUNTRY_ENABLE;
export var AppSelectedSport = ls.get("selectedSports");
export var APP_DOWNLOAD_LINK_ANDROID = "";
export var globalLineupData = {};
export var preTeamsList = {};
export var bannerData = {};
export var LOBBY_FILTER_ARRAY = [];
export var SignupTmpData = {};
export var ReferralData = {};
export var CountryList = [];
export var isBankDeleted = false;
export var OnlyCoinsFlow = 0;
export var EnableBuyCoin = process.env.REACT_APP_BUY_COINS_ENABLE == 1 ? true : false;
export var PlayStoreLink = process.env.REACT_APP_DOWNLOAD_LINK_ANDROID || '';
export var IsSpinWheelSkip = false;
export var AllowRedeem = true;
export var RFContestId = '';
export var minDep = 1;

export const getGendersList = () => [
  { value: "male", label: MALE },
  { value: "female", label: FEMALE }
];

export var ALLOW_LANG = []
export var DASHBOARD_FOOTER = {
  tabs: [
    { tab_key: "lobby" },
    { tab_key: "my-contests" },
    { tab_key: "earn-coins" },
    { tab_key: "my-profile" },
    { tab_key: "more" }
    // { tab_key: 'notification' },
    // { tab_key: 'my-wallet' },
    // { tab_key: 'refer-friend' }
  ],
  config: {
    tab_path: {
      "/lobby": "lobby",
      "/my-contests": "my-contests",
      "/earn-coins": "earn-coins",
      "/my-profile": "my-profile",
      "/leaderboard": "leaderboard",
      "/more": "more",
      "/my-contests/0": "0",
      "/my-contests/1": "1",
      "/my-contests/2": "2",
      "/notification": "notification",
      "/my-wallet": "my-wallet",
      "/refer-friend": "refer-friend"
    },
    my_contest_tab: {
      upcoming: "my-contests/0",
      live: "my-contests/1",
      completed: "my-contests/2"
    }
  }
};

export var LANGUAGE_OBJ = {};
export var NOTIFICATION_DATA = {};
export var MATCH_TYPE = {
  1: "ODI",
  2: "TEST",
  3: "T20",
  4: "T10"
};
export var PAYMENT_TYPE = {
  UPI: "upi",
  WALLET: "wallet",
  NET_BANKING: "net_banking",
  CREDIT_DEBIT_CARD: "credit_debit_card"
};
export var GameType = {
  Pred: "allow_prediction",// 3
  MultiGame: "allow_multigame",// 5
  DFS: "allow_dfs",// 2
};

export class setValue {
  static setCountryCodeData(value) {
    if(value){
      let tmpA = value.split('_');
      ONLY_SINGLE_COUNTRY = tmpA.length > 0 ? tmpA[0] :  0;
      DEFAULT_COUNTRY_CODE = tmpA.length > 1 ? tmpA[1] : '91';
      DEFAULT_COUNTRY = tmpA.length > 2 ? tmpA[2] : 'in';
    }
  }
  static setOnlyCoinFlow(obj) {
    OnlyCoinsFlow = obj ? obj : 0;
  }
  static setAllowRedeem(obj) {
    AllowRedeem = OnlyCoinsFlow == 1 || OnlyCoinsFlow == 2 ? obj : true;
  }
  static setReferralData(obj) {
    ReferralData = obj;
  }
  static setToastObject(obj) {
    TOAST = obj;
  }
  static setOtpSize(value) {
    OTPSIZE = value;
  }
  static setNotificationCount(data) {
    NOTIFICATION_DATA = data;
  }
  static setFilter(filters) {
    LOBBY_FILTER_ARRAY = filters;
  }
  static setCountry(list) {
    CountryList = list;
  }

  static setLanguage(Languages) {
    ALLOW_LANG = Languages;
  }
  static setAppSelectedSport(sport) {
    AppSelectedSport = sport || Sports.default_sport;
  }
  static setAppDownloadLink(link) {
    APP_DOWNLOAD_LINK_ANDROID = link;
  }
  static setBankDeleted(value) {
    isBankDeleted = value;
  }
  static setSportsHubAllow(value) {
    IS_SPORTS_HUB = value;
    if (value) {
      _Map(DASHBOARD_FOOTER.tabs, (item, idx) => {
        if (item.tab_key === "earn-coins") {
          DASHBOARD_FOOTER.tabs[idx]["tab_key"] = "sports-hub";
        }
      });
    }
  }
  static setGameTypesEnable(value) {
    if (value == GameType.Tournament) {
      IS_TOURNAMENT = true;
    }
    if (value == GameType.DFS) {
      IS_DFS = true;
    }
    if (value == GameType.Pred) {
      IS_PREDICTION = true;
    }
    if (value == GameType.Pickem) {
      IS_PICKEM = true;
    }
    if (value == GameType.MultiGame) {
      IS_MULTIGAME = true;
    }
    if (value == GameType.OpenPred) {
      IS_OPEN_PREDICTOR = true;
    }
    if (value == GameType.OpenPredLead) {
      IS_FPP_OPEN_PREDICTOR = true;
    }
  }
  static setSelectedGameType(value) {
    SELECTED_GAMET = value;
  }
  static skipSpinWheel() {
    IsSpinWheelSkip = true;
  }
  static SetRFContestId(id) {
    RFContestId = id;
  }

  static setMinDep(minAddFund) {
    minDep = minAddFund
  }
}