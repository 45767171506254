import WSManager from "../WSHelper/WSManager";
import moment from 'moment';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import ls from 'local-storage';
import { Sports } from "../JsonFiles";
import { AppSelectedSport, setValue, DASHBOARD_FOOTER, TOAST,SELECTED_GAMET, GameType } from "../helper/Constants";
import AppConfig from "../InitialSetup/AppConfig";
import _Map from 'lodash/map';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import _indexOf from 'lodash/indexOf';
import _sumBy from 'lodash/sumBy';
import _debounce from 'lodash/debounce';
import _times from 'lodash/times';
import _mergeWith from 'lodash/mergeWith';
import _throttle from 'lodash/throttle';

export {
    _Map,
    _isNull,
    _isEmpty,
    _isUndefined,
    _cloneDeep,
    _findIndex,
    _filter,
    _indexOf,
    _sumBy,
    _debounce,
    _times,
    _mergeWith,
    _throttle
};

export var APP_MASTER_DATA = '';
export var ANDROID_APP_INSTALLED_VERSION = '';// Used for android app version to be display on more screen


class Utilities {

    static teamFlagURL(flag) {
        return (flag || '').includes('http') ? flag : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.FLAG + (flag || 'flag_default.jpg');
    }
    static playerJersyURL(jersy) {
        return (jersy || '').includes('http') ? jersy : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.JERSY + jersy;
    }
    static getThumbURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.THUMB + file;
    }
    static getPanURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.PAN + file;
    }
    static getUploadURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.UPLOAD + file;
    }
    static getBankURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.BANK + file;
    }
    static getBannerURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.BANNER + file;
    }
    static getAppBannerURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.APPBANNER + file;
    }
    static getRewardsURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.REWARDS + file;
    }
    static getS3URL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.S3ASSETS + file;
    }
    static getBadgeURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.BADGES + file;
    }
    static getMerchandiseURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.MERCHANDISE + file;
    }
    static getSponserURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.SPONSER + file;
    }
    static getCategoryURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.CATEGORY + file;
    }
    static getOpenPredURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.OPENPRED + file;
    }
    static getOpenPredFPPURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.OPENPREDFPP + file;
    }

    static getSettingURL(file) {
        return (file || '').includes('http') ? file : AppConfig.s3.BUCKET + AppConfig.s3.S3_FOLDER.SETTING + file;
    }

    static getTotalUserBalance(mBonusAmount, mRealAmount, mWinningAmount) {
        var bonusAmount = mBonusAmount;
        var realAmount = mRealAmount;
        var winningAmount = mWinningAmount;
        var totalUserBalance = parseFloat(bonusAmount) + parseFloat(realAmount) + parseFloat(winningAmount);
        return totalUserBalance
    }

    static getBalanceAccToMaxPercentOfEntryFee(mEntryFee) {
        var UserBalance = WSManager.getBalance();
        var allowedBonusPercantage = WSManager.getAllowedBonusPercantage();
        var bonusAmount = parseFloat(UserBalance.bonus_amount);
        var realAmount = UserBalance.real_amount;
        var winningAmount = UserBalance.winning_amount;
        var depositAndWinning = parseFloat(realAmount) + parseFloat(winningAmount);
        var maxBonusAccToEntryFee = parseFloat(allowedBonusPercantage) * parseFloat(mEntryFee) / 100;
        let master_data = Utilities.getMasterData();
        if(master_data.max_contest_bonus > 0){
        maxBonusAccToEntryFee = maxBonusAccToEntryFee > master_data.max_contest_bonus ? parseFloat(master_data.max_contest_bonus) : maxBonusAccToEntryFee
        }
        if (bonusAmount > maxBonusAccToEntryFee) {
            return (maxBonusAccToEntryFee + depositAndWinning);
        } else {
            return (bonusAmount + depositAndWinning);
        }
    }
    static callNativeRedirection(sponsorLink,event) {
        event.stopPropagation();

        let data = {
            action: 'sponserLink',
            targetFunc: 'sponserLink',
            type: 'link',
            url: sponsorLink,
            detail: ""
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
    static getValidSponserURL(sponserUrl){
        let sponserUrlConstant = sponserUrl ? sponserUrl: null
        return (sponserUrlConstant);
    }

    static getBalanceInDetail(mEntryFee) {
        let BalanceDetail = {};
        var UserBalance = WSManager.getBalance();
        var allowedBonusPercantage = WSManager.getAllowedBonusPercantage();
        var bonusAmount = parseFloat(UserBalance.bonus_amount);
        var realAmount = UserBalance.real_amount;
        var winningAmount = UserBalance.winning_amount;
        var maxBonusAccToEntryFee = parseFloat(allowedBonusPercantage) * parseFloat(mEntryFee) / 100;
        let master_data = Utilities.getMasterData();
        if(master_data.max_contest_bonus > 0){
            maxBonusAccToEntryFee = maxBonusAccToEntryFee > master_data.max_contest_bonus ? master_data.max_contest_bonus : maxBonusAccToEntryFee
        }
        if (bonusAmount > maxBonusAccToEntryFee) {
            BalanceDetail['Bonus'] = maxBonusAccToEntryFee;
            
        } else {
            BalanceDetail['Bonus'] = bonusAmount;
        }

        var EntryFeeLeft = parseFloat(mEntryFee) - parseFloat(BalanceDetail['Bonus']);
        if(realAmount >= EntryFeeLeft){
            BalanceDetail['Deposit'] = EntryFeeLeft;
        }
        else{
            BalanceDetail['Deposit'] = realAmount;
            if(winningAmount >= (EntryFeeLeft - realAmount)){
                BalanceDetail['Winning'] = (EntryFeeLeft - realAmount);
            }
            else{
                BalanceDetail['Winning'] = winningAmount;
            }
        }
        return BalanceDetail;
    }

    static getTotalBalance(data) {
        return (parseFloat(data.winning_amount) + parseFloat(data.bonus_amount) + parseFloat(data.real_amount)).toFixed(2)
    }

    static setDefaultSport() {
        let master_data = Utilities.getMasterData();
        ls.set('selectedSports', AppSelectedSport || master_data.default_sport);
        setValue.setAppSelectedSport(AppSelectedSport || master_data.default_sport);
    }

    static getMaxBonusAllowedOfEntryFeeContestWise(mEntryFee, maxBonusAllowed) {
        var UserBalance = WSManager.getBalance();
        var allowedBonusPercantage = maxBonusAllowed;
        var bonusAmount = parseFloat(UserBalance.bonus_amount);
        var realAmount = UserBalance.real_amount;
        var winningAmount = UserBalance.winning_amount;
        var depositAndWinning = parseFloat(realAmount) + parseFloat(winningAmount);
        var maxBonusAccToEntryFee = parseFloat(allowedBonusPercantage) * parseFloat(mEntryFee) / 100;
        let master_data = Utilities.getMasterData();
        if(master_data.max_contest_bonus > 0){
            maxBonusAccToEntryFee = maxBonusAccToEntryFee > master_data.max_contest_bonus ? parseFloat(master_data.max_contest_bonus) : maxBonusAccToEntryFee
            }
        if (bonusAmount > maxBonusAccToEntryFee) {
            return (maxBonusAccToEntryFee + depositAndWinning);
        } else {
            return (bonusAmount + depositAndWinning);
        }
    }
    /**
     * @description This function is responsible to get 18 year old date
     * @param date UTC date
     * @return 18 years old date
    */
    static get18YearOldDate = (date) => {
        return new Date(moment().subtract(18, 'years'));
    }
    /**
     * @description This function is responsible to get 18 year old date
     * @param date UTC date
     * @return 18 years old date
    */
    static getFormatedDate = (data) => {
        return moment(data.date).format(data.format);
    }

    /**
     * @description This function is responsible to convert UTC date to local date
     * @param date UTC date
     * @return Local date
    */
    static getUtcToLocal = (date) => {
        return moment(date).utc(true).local().format();
    }
    /**
     * @description This function is responsible to convert local date to UTC date
     * @param date Local date
     * @return UTC date
    */
    static getLocalToUtc = (date,formate) => {
        return moment.utc(date).format(formate);
    }

    static getFormatedDateTime = (date, format) => {
        if (format) {
            return moment.utc(date).local().format(format);
        }
        return moment(date).utc().local().format();
    }
    /**
     * @description This function is to get know that count down timer should be display or not
     * @param item Fixture item
     */
    static showCountDown(item) {
        let scheduleDate = Utilities.getFormatedDateTime(item.game_starts_in);
        let currentDate = Utilities.getFormatedDateTime(Date.now());
        var now = moment(currentDate); //todays date
        var end = moment(scheduleDate); // another date
        var duration = moment.duration(end.diff(now));
        var hours = duration.asHours();
        var minutes = duration.asMinutes();
        return ((minutes >= 0) && (hours <= 24));
    }

    static minuteDiffValue(item) {
        let currentDate = Utilities.getFormatedDateTime(Date.now());
        let scheduleDate = Utilities.getFormatedDateTime(item.date);
        var now = moment(currentDate);
        var end = moment(scheduleDate);
        var duration = moment.duration(now.diff(end));
        var minutes = duration.asMinutes();
        return minutes;
    }

    static scrollToTop() {
        window.scrollTo(0, 0)
    }

    static kFormatter(num) {
        return Math.abs(num) > 99999 ? Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + ' L' : (Math.abs(num) > 9999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + ' K' : Math.sign(num) * Math.abs(num).toFixed(0))
    }
    
    static kLowerFormatter(num) {
        return Math.abs(num) > 9999 ? Math.sign(num) * (Math.floor(Math.abs(num) / 1000)) + 'k' : Math.sign(num) * Math.abs(num).toFixed(0)
    }

    static numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static showToast(message = "", duration = 2000, icon = 'icon-ic-info') {
        if (message != "" && TOAST) {
            TOAST.showToast({ message: message, duration: duration, icon: icon });
        }
    }
    static handleAppBackManage = (pageType) => {
        if (window.ReactNativeWebView) {
            let data = {
                action: 'SessionKey',
                targetFunc: 'SessionKey',
                page:pageType,
                SessionKey: WSManager.getToken() ?  WSManager.getToken() : WSManager.getTempToken() ? WSManager.getTempToken():'',
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }   
    }

    static getSelectedSportsForUrl(sports_id) {
        let selectedSports = sports_id;
        if (typeof sports_id == "undefined") {
            selectedSports = AppSelectedSport;
        }
        let sports_url = '';
        if (selectedSports != null) {
            sports_url = Sports.url[selectedSports] ? Sports.url[selectedSports].toLowerCase() : '';
        }
        return sports_url
    }

    static getGameTypeHash() {
        var lobbyHash = ''
        if(SELECTED_GAMET == GameType.Pred){
            lobbyHash = '#prediction'
        }
        if(SELECTED_GAMET == GameType.MultiGame){
            lobbyHash = '#multigame'
        }
        return lobbyHash
    }

    static getUrlSports() {
        var sportsId = AppSelectedSport;
        let url = window.location.href;
        
        if (url.includes("#")) {
            let urlArr = url.split("#");
            _Map(urlArr,(item)=>{
                let selectedSports = item.toLowerCase();
                if (selectedSports in Sports) {
                    sportsId = Sports[selectedSports] + "";
                    ls.set('selectedSports', sportsId)
                    setValue.setAppSelectedSport(sportsId);
                }
            })
        }
        sportsId = (sportsId === "null" || sportsId === null) ? Sports.default_sport : sportsId
        return sportsId + "";
    }

    static setUrlParams = (LobyyData) => {

        let dateformaturl = Utilities.getFormatedDateTime(LobyyData.season_scheduled_date);
        dateformaturl = new Date(dateformaturl);
        let dateformaturlDate = ("0" + dateformaturl.getDate()).slice(-2)
        let dateformaturlMonth = ("0" + (dateformaturl.getMonth() + 1)).slice(-2)
        dateformaturl = dateformaturlDate + '-' + dateformaturlMonth + '-' + dateformaturl.getFullYear();
        let urlParams = LobyyData.home + "-vs-" + LobyyData.away + "-" + dateformaturl;
        return urlParams.toLowerCase();
    }

    static replaceAll = (stringLabel, stringToFind, stringToReplace) => {
        if (stringToFind === stringToReplace) return stringLabel;
        var temp = stringLabel;
        var index = temp.indexOf(stringToFind);
        while (index !== -1) {
            temp = temp.replace(stringToFind, stringToReplace);
            index = temp.indexOf(stringToFind);
        }
        return temp;
    }

    static getMasterData() {
        return APP_MASTER_DATA || '';
    }

    static setMasterData(data) {
        APP_MASTER_DATA = data;
    }

    static getAndroidAppVersion() {
        return ANDROID_APP_INSTALLED_VERSION;
    }

    static setAndroidAppVersion(version) {
        ANDROID_APP_INSTALLED_VERSION = version;
    }

    static getGameTypeSports() {
        var sportData = Utilities.getMasterData().sports_hub || [];

        let tempArray = []
        sportData.map((item, key) => {
            tempArray.push({ 
                sports_hub_id: item.sports_hub_id, 
                game_key: item.game_key , 
                allowed_sports: item.allowed_sports
            })
        })
        return tempArray;
    }

    static isValidPhoneNumber = (phone) => {
        if (!phone) {
            return false;
        }
        let formatedNum = parsePhoneNumberFromString("+"+phone.toString());
        return formatedNum && formatedNum.isValid();
    }

    static setCpSession() {
        if(window.ReactNativeWebView) return null
        const urlParams = new URLSearchParams(window.location.search);
        const utm_source = urlParams.get('utm_source');
        if(utm_source) {
            window.sessionStorage.setItem("c367d", encodeURIComponent(window.location.search));
        }
    }
    static getCpSession() {
        if(window.ReactNativeWebView) return null
        const urlParams = new URLSearchParams(decodeURIComponent(window.sessionStorage.getItem("c367d")));
        return {
            source: urlParams.get('utm_source') || '',
            medium: urlParams.get('utm_medium') || '',
            campaign: urlParams.get('utm_campaign') || '',
            term: urlParams.get('utm_term') || '',
            content: urlParams.get('utm_content') || '',
        }
    }
    static getRandomRefrenceId(){
        // var navigator_info = window.navigator;
        // var screen_info = window.screen;
        // var uid = navigator_info.mimeTypes.length;
        // uid += navigator_info.userAgent.replace(/\D+/g, '');
        // uid += navigator_info.plugins.length;
        // uid += screen_info.height || '';
        // uid += screen_info.width || '';
        // uid += screen_info.pixelDepth || '';
       let uid = Math.floor((Math.random() * 999999999 * 888888 ) + 1)
        return uid;
    }
    static getCpSessionPath() {
        if(window.ReactNativeWebView) return null
        return decodeURIComponent(window.sessionStorage.getItem("c367d"))
    }
}

export function parseURLDate(date){
    let dateformaturl = Utilities.getUtcToLocal(date);
    dateformaturl = new Date(dateformaturl);
    let dateformaturlDate = ("0" + dateformaturl.getDate()).slice(-2)
    let dateformaturlMonth = ("0" + (dateformaturl.getMonth() + 1)).slice(-2)
    dateformaturl = dateformaturlDate + '-' + dateformaturlMonth + '-' + dateformaturl.getFullYear();
    return dateformaturl;
}

export function BannerRedirectLink(result, props){
    if (result.banner_type_id == 1) {
        let dateformaturl = parseURLDate(result.schedule_date);
        let contestListingPath = Utilities.getSelectedSportsForUrl().toLowerCase() + '/contest-listing/' + result.collection_master_id + '/' + result.home + "-vs-" + result.away + "-" + dateformaturl + "?sgmty=" +  btoa(SELECTED_GAMET);
        props.history.push({ pathname: contestListingPath });
    }
    else if (result.banner_type_id == 2) {
        props.history.push({ pathname: '/refer-friend' });
    }
    else if (result.banner_type_id == 3) {
        props.history.push({ pathname: '/add-funds' });
    }
    else if (result.banner_type_id == 4) {
        if(result.target_url.includes('/refer-friend')){
            props.history.push({ pathname: '/refer-friend' });
        }else if(result.target_url.includes('/add-funds')){
            props.history.push({ pathname: '/add-funds' });
        }else{
            window.open(result.target_url, "_blank")
        }
    }
}

export function getImageBaseUrl(image) {
    let IMAGE_BASE_URL = Utilities.getS3URL(image);
    return process.env.NODE_ENV === 'production' ? IMAGE_BASE_URL : require('./../assets/img/' + image);
}

export function _handleWKeyDown(event) {
        const BACKSPACE = 8;
        const LEFT_ARROW = 37;
        const RIGHT_ARROW = 39;
        const DELETE = 46;
        const ENTER = 13;

        var isValidKey = event.keyCode === ENTER || event.keyCode === BACKSPACE || event.keyCode === LEFT_ARROW || event.keyCode === RIGHT_ARROW || event.keyCode === DELETE;
        if (this && event.target instanceof HTMLInputElement) {
            const regex = /^[0-9\b]+$/;
            if (event.key !== '' && !regex.test(event.key) && !isValidKey) {
                event.preventDefault();
            }
        }
}

export {
    Utilities
};
/**
    * @description After Social login check if user is existing or new user and navigate to appropriate step
    * @param data data received from social plateform
    * @param fbUser in case user login through FB then it will have user data else it will be null
    * @param googleUser in case user login through Google then it will have user data else it will be null
   */
export function checkFlow(nextStepData) {
    let pathName = '/' + nextStepData.data.next_step;

    if (nextStepData.data.next_step === 'login_success') {
        WSManager.setToken(nextStepData.data.Sessionkey);
        pathName = '/';
    } else if (nextStepData.data.next_step === 'phone') {
        pathName = '/pick-mobile';
    }

    return { pathname: pathName, state: { nextStepData: nextStepData } }
}

export function isFooterTab(tab_key) {
    let allFooterTabs = DASHBOARD_FOOTER.tabs;
    for (let i = 0; i < allFooterTabs.length; i++) {
        if (allFooterTabs[i].tab_key === tab_key) {
            return true;
        }
    }
    return false;
}

export function sendMessageToApp(action) {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(action));
    }
}

export function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return new File([theBlob], fileName, { name: fileName, lastModifiedDate: Date.now() });
}
export function compressImg(mfile, options) {
    const imageCompression = require('browser-image-compression').default;
    return imageCompression(mfile, options);
}
