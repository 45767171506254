import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as Constants from "./helper/Constants";

import translationEng from "./assets/i18n/translations/en.json";
import translationHi from "./assets/i18n/translations/hi.json";

Constants.setValue.setLanguage([
  { value: "en", label: "English", desc: "English" },
  { value: "hi", label: "हिंदी", desc: "Hindi" }
])

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: true,
    // lng: "en",
    fallbackLng: "en", // use en if detected lng is not available
    resources: {
      en: {
        translations: process.env.REACT_APP_SERVE_LANG_LOCALLY == '1' ? translationEng : Constants.LANGUAGE_OBJ
      },
      hi: {
        translations: process.env.REACT_APP_SERVE_LANG_LOCALLY == '1' ? translationHi : Constants.LANGUAGE_OBJ
      }
    },
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },
    react: {
      wait: true,
      useSuspense: false,
    }
  });

export default i18n;
