import app_config from "../InitialSetup/AppConfig";

export class DeviceToken {
    static getDeviceId = () => {
        return deviceID;
    }

    static setDeviceId = (token) => {
        deviceID = token;
    }
}

export const AppName = process.env.REACT_APP_NAME;
export const nodeBaseURL = app_config.apiGateway.nodeURL;
export const predictionBaseUrl = app_config.apiGateway.PREDICTION_URL;
export const baseURL = app_config.apiGateway.URL;
export const userURL = app_config.apiGateway.USER_URL;
export const userNewURL = app_config.apiGateway.USER_NEW_URL;
export const fantasyURL = app_config.apiGateway.FANTASY_URL;
export const scorecardURL = app_config.apiGateway.SCORECARD_URL;
export const S3_BUCKET_PATH = app_config.s3.BUCKET;
export const BUCKET_DATA_PREFIX = app_config.s3.BUCKET_DATA_PREFIX;
export const deviceType = "3";
export const deviceTypeAndroid = "1";
export var deviceID = "";

export const successCode = 200;
export const sessionExpireCode = 401;
export const inQueueCode = 400;

// Social
export const FB_APP_ID = app_config.cognito.FB_APP_ID;
export const GPLUS_ID = app_config.cognito.GOOGLE_CLIENT_ID;
//Analytics Constant
export const GA_PROFILE_ID = app_config.cognito.GOOGLE_PROFILE_ID;//"UA-150774041-1"; //supersix fantasy-akhada-9d552c4f0e8f.json  reachfantasyarena@gmail.com

//Api Constant
// export const MASTER_DATA = "user/auth/get_app_master_list";
export const MASTER_DATA = "user/auth/get_mobile_app_master_list";
export const STATIC_PAGE_DATA = "user/common/get_static_content";
export const VALIDATE_OTP = "user/auth/validate_otp";
export const VALIDATE_EMAIL_OTP = "user/emailauth/validate_otp";
export const GET_SIGNUP_REFERRAL_DATA = "user/auth/get_signup_referral_data";
export const VALIDATE_LOGIN = "user/auth/validate_login";
export const LOGIN = "user/auth/login";
export const EDIT_MOBILE = "user/profile/new_number_send_otp";
export const VERIFY_EDITED_MOBILE = "user/profile/new_number_verify_and_update";
export const EDIT_EMAIL = "user/profile/new_email_otp_send";
export const VERIFY_EDITED_EMAIL = "user/profile/new_email_verify_and_update";
export const EMAIL_LOGIN = "user/emailauth/login";
export const RESEND_OTP = "user/auth/resend_otp";
export const RESEND_EMAIL_OTP = "user/emailauth/resend_otp";
export const FORGOT_PASSWORD = "user/emailauth/forgot_password";
export const RESET_PASSWORD = "user/emailauth/reset_password";
export const VALIDATE_FORGOT_PASSWORD = "user/emailauth/forgot_password_validate_code";
export const GET_LINEUP_MASTER_DATA = "fantasy/lineup/get_lineup_master_data";
export const GET_ALL_ROSTER = "fantasy/lineup/get_all_roster";
export const LINEUP_PROCCESS = "fantasy/lineup/lineup_proccess"; // Create/Edit Lineup
export const GET_USER_LINEUP = "fantasy/lineup/get_user_lineup";
export const SOCIAL_LOGIN = "user/auth/social_login";
export const SOCIAL_EMAIL_LOGIN = "user/emailauth/social_login";

export const GET_LINPEUP_WITH_SCORE = "fantasy/contest/get_linpeup_with_score";
//Player card
export const GET_PLAYERCARD = "fantasy/common/get_playercard";
export const GET_PLAYER_BREAKDOWN = "fantasy/common/get_player_breakdown";
//Leaderboard
export const GET_INVITE_CODE = "fantasy/contest/get_contest_invite_code";
// Profile
export const UPDATE_SIGNUP_DATA = "user/profile/update_profile_data";
export const VERIFY_PAN_INFO = "user/profile/verify_pan_info";
export const VERIFY_BANK_ACCOUNT = "user/profile/verify_bank_account";
export const UPDATE_EMAIL_SIGNUP_DATA = "user/emailauth/update_profile_data";
export const CHANGE_PASSWORD = "user/profile/change_password";
export const UPDATE_DEVICE_TOKEN = "user/profile/update_device_id";
export const GET_MY_PROFILE = "user/profile/profile_detail";
export const DO_UPLOAD = "user/profile/do_upload";
export const UPDATE_PROFILE = "user/profile/update_basic_info";
export const UPDATE_STATE_DATA = "user/profile/update_state_city";
export const GET_ALL_COUNTRY = "user/profile/get_country_list";
export const GET_ALL_STATE = "user/profile/get_state_list";
export const UPDATE_BANK_ACC_DETAILS = "user/profile/update_bank_ac_detail";
export const DO_UPLOAD_PAN = "user/profile/do_upload_pan";
export const UPDATE_PAN_INFO = "user/profile/update_pan_info";
export const DO_UPLOAD_BANK_DOCUMENT = "user/profile/do_upload_bank_document";
export const CHECK_USERNAME_AVAIBILITY = "user/profile/check_username";
export const UPDATE_USERNAME = "user/profile/update_username";
export const PLAYING_EXPERIENCE = "user/profile/get_playing_experience";
export const DELETE_BANK_ACC_DETAILS = "user/profile/delete_bank_details";
//lobby
export const GET_LOBBY_FIXTURE = "fantasy/lobby/get_lobby_fixture";
export const GET_FIXTURE_CONTEST_LIST = "fantasy/lobby/get_fixture_contest";
export const GET_FIXTURE_DETAIL = "fantasy/lobby/get_fixture_details";
export const GET_CONTEST_DETAIL = "fantasy/contest/get_contest_detail";
export const GET_CONTEST_USERS = "fantasy/contest/get_contest_users";
export const GET_USER_LINEUP_LIST = "fantasy/lobby/get_user_lineup_list";
export const GET_MY_COLLECTION_LIST = "fantasy/contest/get_user_joined_fixture_by_status";
export const GET_MY_CONTEST_LIST = "fantasy/contest/get_user_contest_by_status";
export const GET_CONTEST_LEADERBOARD = "fantasy/contest/get_contest_leaderboard";
export const GET_CONTEST_USER_LEADERBOARD = "fantasy/contest/get_contest_user_leaderboard_teams";
export const GET_GAME_USER_JOIN_COUNT = "fantasy/contest/get_user_contest_join_count";
export const GET_PUBLIC_CONTEST = "fantasy/contest/get_public_contest";
export const GET_LOBBY_BANNER_LIST = "fantasy/lobby/get_lobby_banner_list";
export const GET_USER_LINEUP_TEAM_NAME = "fantasy/lineup/get_user_match_team_data";
// Finance
export const GET_TRANSACTION_HISTORY = "user/finance/get_transaction_history";
export const GET_USER_BALANCE = "user/finance/get_user_balance";
export const WITHDRAW_BALANCE = "user/finance/withdraw";
export const WITHDRAW_BALANCE_NEW = "user/withdraw/auto";

export const DEPOSIT_BY_PAYUMONEY = "user/payumoney/deposit";
export const DEPOSIT_BY_PAYTM = "user/paytm/deposit";
export const DEPOSIT_BY_GOCASH = "user/gocash/deposit";
export const DEPOSIT_BY_JUSTPAY = "user/gocash/payment_initiate";
export const DEPOSIT_BY_PAYPAL = "user/paypal/deposit";
export const DEPOSIT_BY_IPAY = "user/ipay/deposit";
export const DEPOSIT_BY_PAYSTACK = "user/paystack/deposit";
export const DEPOSIT_BY_RAZORPAY = "user/razorpay/deposit";
export const VALIDATE_PROMO_CODE = "user/finance/validate_promo_code";
export const GET_DEALS = "user/finance/get_deals";
export const GET_PENDING_WITHDRAW = "user/finance/get_pending_withdraw";
//ReferFriendFragment
export const GET_AFFILIATE_MASTER_DATA = "user/affiliate/get_affiliate_master_data";
export const GET_MASTER_DATA_REF = "user/affiliate/get_master_data";
export const UPDATE_REF_CODE = "user/profile/update_referral_code";
export const GET_AFFILIATE_MYREFERRAL_LIST = "user/affiliate/get_referral_list";
export const GET_USER_EARN_MONEY = "user/affiliate/get_user_earning_by_friend";
export const GET_SAVE_SHORTEN_URL = "user/shorturl/save_shortened_url";
export const GET_SHORTENED_URL = "user/shorturl/get_shortened_url";
export const GET_CONTEST_LINEUP_EXPORT = "fantasy/contest/download_contest_teams";
// Join Game  
export const JOIN_FANTASY_CONTEST_GAME = "fantasy/contest/join_game";
export const MULTITEAM_JOIN_GAME = "fantasy/contest/multiteam_join_game";
export const GET_USER_SWITCH_TEAM_LIST = "fantasy/contest/get_user_switch_team_list";
export const SWITCH_TEAM_CONTEST = "fantasy/contest/switch_team_contest";
// Promo Code 
export const VALIDATE_CONTEST_PROMO_CODE = "fantasy/contest/validate_contest_promo_code";
//Notification
export const GET_UNREAD_NOTIFICATION = "user/notification/get_unread_notification";
export const GET_USER_NOTIFICAITONS = "user/notification/get_notification";

//Create A League Api's  
export const CREATE_CONTEST_MASTER_DATA = "fantasy/private_contest/create_contest_master_data";
export const GET_MATCHES_BY_LEAGUE_ID = "fantasy/private_contest/get_matches_by_league_id";
export const CREATE_USER_CONTEST = "fantasy/private_contest/create_user_contest";
//Have A LeagueCode Api
export const CHECK_ELIGIBILITY_FOR_CONTEST = "fantasy/contest/check_eligibility_for_contest";
//Scorring Api 
export const GET_SCORING_MASTER_DATA = "fantasy/contest/get_scoring_master_data";
//Lobby/Contest List Filter Master Data Api
export const GET_FILTER_MASTER_DATA = "fantasy/lobby/get_lobby_filter";
export const ACTIVATE_ACCOUNT = "user/auth/activate_account";
//For edit profile -> resend email verification link
export const RESEND_EMAIL_VERIFICATION_LINK = "user/auth/resend_email_verification_link";
export const GET_DOWNLOAD_APP_LINK = "user/auth/send_applink";
//Logout Api 
export const GET_LOGOUT = "user/auth/logout";

//Coins Api
export const GET_DAILYCOIN = "user/coins/get_daily_streak_coins";
export const CLAIM_COINS = "user/coins/claim_coins";
export const EARN_COIN_LIST = "user/coins/get_earn_coins_list";
export const GET_REWARD_LIST = "user/coins/get_reward_list";
export const REDEEM_REWARD = "user/coins/redeem_reward";
export const UPDATE_USER_SETTING = "user/profile/update_user_setting";
export const GET_FB_LIST = "user/coins/get_feedback_question_list";
export const SAVE_FEEDBACK = "user/coins/save_feedback";

//MultiGame Api.
export const GET_LOBBY_MULTI_GAME = "fantasy/multigame/get_lobby_fixture";
export const GET_FIXTURE_DETAIL_MULTI_GAME = "fantasy/multigame/get_fixture_details";
export const GET_CONTEST_DETAIL_MULTI_GAME = "fantasy/multigame/get_contest_detail";
export const GET_PUBLIC_CONTEST_MULTI_GAME = "fantasy/multigame/get_public_contest";
export const GET_MY_COLLECTION_LIST_MULTI_GAME = "fantasy/multigame/get_user_joined_fixture_by_status";
export const CHECK_ELIGIBILITY_FOR_CONTEST_MULTI_GAME = "fantasy/multigame/check_eligibility_for_contest";

//Prediction
export const GET_LOBBY_PREDICTION = "prediction/prediction/get_lobby_fixture";
export const GET_PREDICTION_LEADERBORD = "prediction/prediction/get_prediction_leaderboard";
export const GET_PREDICTION_USERS = "prediction/prediction/get_prediction_participants";
export const GET_PREDICTIONS_CONTEST = ":4000/prediction/get_predictions";
export const GET_PREDICTIONS_DETAIL = ":4000/prediction/get_prediction_detail";
export const MAKE_PREDICTIONS = "/prediction/prediction/make_prediction";
export const PREDICTIONS_SEASON = ":4000/prediction/get_my_prediction_season";
export const MY_PREDICTIONS = ":4000/prediction/get_my_contest_fixtures_predictions";
export const CHECK_PREDICTION_JOINED = ":4000/prediction/check_prediction_user_joined";

//  new prediction apis
export const GET_MY_PREDICTION_SEASON =  "/prediction/get_my_prediction_season"
export const GET_LOBBY_PREDICTION_NEW =  "/prediction/get_lobby_fixture"
export const MAKE_PREDICTIONS_NEW =  "/prediction/prediction/make_prediction"
export const GET_SEASON_BY_SEASON_UID =  "/prediction/prediction/get_season_by_season_uid"
export const GET_MY_CONTEST_FIXTURE_PREDICTIONS =  "/prediction/get_my_contest_fixtures_predictions" // use API_BASE_URL 
export const GET_PREDICTIONS =  "/prediction/get_predictions"
export const GET_USER_PREDICTION_TRANSACTIONS =  "/prediction/get_user_transaction"
export const GET_PREDICTION_LEADERBORD_NEW = "/prediction/get_prediction_leaderboard";

//FreeToPlay
export const GET_LOBBY_FREE_TO_PLAY = "fantasy/freetoplay/get_lobby_fixture";
export const GET_MY_CONTEST_LIST_FREE_TO_PLAY = "fantasy/freetoplay/get_user_joined_fixture_by_status";
export const GET_MINI_LEAGUE_BY_STATUS = "fantasy/mini_league/get_mini_league_by_status"
export const GET_MINI_LEAGUE_DETAILS = "fantasy/mini_league/get_mini_league_detail"
export const GET_FIXTURE_MINI_LEAGUE= "fantasy/freetoplay/get_fixture_mini_leagues"
export const GTE_MINI_LEAGUE_LEADER_BOARD="fantasy/mini_league/get_mini_league_leaderboard"
export const GTE_USER_MINI_LEAGUE_LEADER_BOARD="fantasy/mini_league/get_user_mini_league_leaderboard"
export const GTE_USER_MINI_LEAGUE_LEADER_BOARD_MATCHES="fantasy/mini_league/get_user_leaderboard_matches"
export const GET_MINI_LEAGUE_MY_CONTEST_LIST = "fantasy/freetoplay/get_user_contest_by_status";
export const GET_MINI_LEAGUE_UPCOMING_FIXTURE="fantasy/freetoplay/get_upcoming_fixture"

// buy coin module
export const GET_COINS_PACKAGE_LIST = "user/coins_package/get_coins_package_list";
export const BUY_COINS = "user/coins_package/buy_coins";
//CASHFREE
export const GET_CASHFREE_GATWAY_LIST = "user/gocash/get_wallet_bank_list";

//Referal 
export const GET_REFERAL_LEADERBOARD="/fantasy/referral_leaderboard/get_referral_leaderboard"
export const GET_REFERAL_PRIZES="/fantasy/referral_leaderboard/get_referral_prizes"

//Network Fantasy
export const MULTITEAM_JOIN_GAME_NETWORK_FANTASY = "network/contest/multiteam_join_game";

export const MASTER_DATA_NETWORK_FANTASY="network/auth/get_app_master_list"
export const GET_FIXTURE_DETAIL_NETWORK_FANTASY = "network/lobby/get_fixture_details";
export const GET_FIXTURE_CONTEST_LIST_NETWORK_FANTASY = "network/lobby/get_fixture_contest";
export const GET_USER_LINEUP_LIST_NETWORK_FANTASY = "network/lobby/get_user_lineup_list";
export const GET_CONTEST_SCORE_CARD_NETWORK_FANTASY = "network/contest/get_contest_score_card";
export const GET_PLAYER_BREAKDOWN_NETWORK_FANTASY = "network/common/get_player_breakdown";

//SCORE CARD
export const GET_CONTEST_SCORE_CARD = "fantasy/contest/get_contest_score_card";
export const JOIN_FANTASY_CONTEST_GAME_NETWORK_FANTASY = "network/contest/join_game";
export const GET_CONTEST_DETAIL_NETWORK_FANTASY = "network/contest/get_contest_detail";
export const GET_GAME_USER_JOIN_COUNT_NETWORK_FANTASY = "network/contest/get_user_contest_join_count";
export const GET_CONTEST_USERS_NETWORK_FANTASY = "network/contest/get_contest_users";

export const GET_USER_LINEUP_NETWORK_FANTASY = "network/lineup/get_user_lineup";
export const GET_LINEUP_MASTER_DATA_NETWORK_FANTASY = "network/lineup/get_lineup_master_data";

export const GET_ALL_ROSTER_NETWORK_FANTASY = "network/lineup/get_all_roster";
export const GET_USER_LINEUP_TEAM_NAME_NETWORK_FANTASY = "network/lineup/get_user_match_team_data";

export const LINEUP_PROCCESS_NETWORK_FANTASY = "network/lineup/lineup_proccess"; // Create/Edit Lineup
export const SWITCH_TEAM_CONTEST_NETWORK_FANTASY = "network/contest/switch_team_contest";

export const GET_INVITE_CODE_NF = "network/contest/get_contest_invite_code";
export const GET_SAVE_SHORTEN_URL_NF = "network/common/save_shortened_url";
export const GET_SHORTENED_URL_NF = "network/common/get_shortened_url";
export const GET_PUBLIC_CONTEST_NF = "network/contest/get_public_contest";
export const GET_LINPEUP_WITH_SCORE_NF = "network/contest/get_linpeup_with_score";
export const GET_PLAYERCARD_NF = "network/common/get_playercard";
export const GET_USER_BALANCE_NF = "network/user/get_user_balance";
export const GET_MY_COLLECTION_LIST_NF = "network/contest/get_user_joined_fixture_by_status";
export const GET_MY_CONTEST_LIST_NF = "network/contest/get_user_contest_by_status";
export const GET_USER_SWITCH_TEAM_LIST_NF = "network/contest/get_user_switch_team_list";
export const GET_CONTEST_LINEUP_EXPORT_NF = "network/contest/download_contest_teams";
export const GET_CONTEST_LEADERBOARD_NF = "network/contest/get_contest_leaderboard";
export const GET_CONTEST_USER_LEADERBOARD_NF = "network/contest/get_contest_user_leaderboard_teams";
//Team Comparison
export const GET_LINPEUP_WITH_TEAM_COMPARE = "fantasy/contest/get_compare_teams";
// Session tracking 
export const TRACK_ACTIVE_SESSION = "user/profile/track_active_session";

