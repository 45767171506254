const prod = {
    s3: {
        BUCKET: process.env.REACT_APP_S3_URL,
        S3_FOLDER: {
            UPLOAD: 'upload/',
            SETTING: 'upload/setting/',
            BANNER: 'upload/banner/',
            APPBANNER: 'upload/app_banner/',
            FLAG: 'upload/flag/',
            JERSY: 'upload/jersey/',
            PAN: 'upload/pan/',
            THUMB: 'upload/profile/thumb/',
            BANK: 'upload/bank_document/',
            REWARDS: 'upload/rewards/',
            S3ASSETS: 'assets/img/',
            BADGES:'upload/badges/',
            MERCHANDISE:'upload/merchandise/',
            SPONSER:'upload/sponsor/',
            CATEGORY: 'upload/category/',
            OPENPRED: 'upload/open_predictor/',
            OPENPREDFPP: 'upload/fixed_open_predictor/sponsor/'
        },
        BUCKET_DATA_PREFIX: process.env.REACT_APP_S3_PREFIX,
        BUCKET_STATIC_DATA_ALLOWED: true,
    },
    apiGateway: {
        URL: process.env.REACT_APP_BASE_URL + '/',
        USER_URL: process.env.REACT_APP_USER_BASE_URL + '/',
        USER_NEW_URL: process.env.REACT_APP_NEW_USER_BASE_URL + '/',
        FANTASY_URL: process.env.REACT_APP_FANTASY_BASE_URL + '/',
        nodeURL: process.env.REACT_APP_NODE_URL + ":4000",
        SCORECARD_URL: process.env.REACT_APP_SCORECARD_BASE_URL,
        PREDICTION_URL:process.env.REACT_APP_PREDICTION_BASE_URL
    },
    cognito: {
        FB_APP_ID: process.env.REACT_APP_FB_APP_ID,
        GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        GOOGLE_PROFILE_ID: process.env.REACT_APP_GOOGLE_PROFILE_ID,
    }
};

export default {
    ...prod
};
