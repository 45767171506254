export var Sports = {}
export var SportsIDs = {
    baseball: 1,
    football: 2,
    basketball: 4,
    soccer: 5,
    cricket: 7,
    hockey: 6,
    kabaddi: 8,
    badminton: 10,
    tennis: 11,
    volleyball: 12,
    NCAA: 13,
    CSGO: 14,
    DOTA2: 15,
    LOL: 16,
}
export class SetFantasyList {

    static FantasyList(obj) {
        Sports = obj;
    }
}
