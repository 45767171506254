import i18n from "../i18n";
import { Utilities } from '../Utilities/Utilities';

function transtale(key) {
    return i18n.t(key)
}

export function replace_PANTOID(string){
    if (Utilities.getMasterData().int_version == 1) {
        const PAN_TEXT = PAN || '';
        const PAN_TO_ID_TEXT = PAN_TO_ID || '';
        const regexPAN = new RegExp(PAN_TEXT, "g");
        return string.replace(regexPAN, PAN_TO_ID_TEXT);
    } else {
        return string
    }
}

export function changeLanguageString() {
    translateHomeData();
    translateSportsHub();
    translateCoinData();
    translateOnboardigData();
    translateDrawer();
    translateLeaderboard();
    translateContestData();
    translateRosterData();
    translatePlayerCardData();
    translateMyContestData();
    translateCreateContestData();
    translateWalletData();
    translateInviteData();
    translateProfileData();
    translateNotificationData();
    translateStaticPagesData();
    translateContestListingData();
    translateItemData();
    translateFTPData();
    translateReferralFriend();
    translateEsport();
    translateAffiliate();
    translateCoinEntry();
    translateCoinModule();
    translateTeamCompare();
    translateReverseFantasy();
    translateRules();
}

//Home Page
export var FIXTURES_HEADER1 = '';
export var FIXTURES_HEADER2 = '';
export var DOWNLOAD_NOW = '';
export var PLAY_NOW = '';
export var PLAY = '';
export var ABOUT_US = '';
export var TERMS_CONDITION = '';
export var PRIVACY_POLICY = '';
export var THE_GAME = '';
export var HOW_TO_PLAY = '';
export var HOW_TO_PLAY_FANTASY_SPORTS = '';
export var RULES_SCORING_SYSTEM = '';
export var SUPPORT = '';
export var FAQS = '';
export var CONTACT_US = '';
export var OFFERS = '';
export var LEGALITY = '';
export var REFUND_POLICY = '';
export var CONTACT_US_EMAIL = '';
export var CONTACT_US_PHONE = '';
export var DOWNLOAD_APP = '';
export var DOWNLOAD_MOBILE_APP = '';
export var MOBILE_APP_DESC = '';
export var DOWNLOAD_MEESAGE='';
export var ENTER_MOBILE_NUMBER = ''
export var ENTER_MOBILE = ''
export var GET_LINK_NOW = ''
export var CHANGE_LANGUAGE = ''
export var DISCLAIMER_LINE1 = ''
export var DISCLAIMER_LINE_SUB_TITLE1 = ''
export var DISCLAIMER_LINE_SUB_TITLE2 = ''
export var DISCLAIMER_LINE_SUB_TITLE3 = ''
export var DISCLAIMER_LINE_SUB_TITLE4 = ''
export var DISCLAIMER_LINE_SUB_TITLE5 = ''
export var DISCLAIMER_LINE2 = ''
export var DISCLAIMER_LINE3 = ''
export var DISCLAIMER_LINE4 = ''


//Onboarding
export var SIGN_UP = '';
export var LOGIN = '';
export var GET = '';
export var INSTANTLY = '';
export var ON_EVERY_SIGNUP = '';
export var T_N_C_APPLY = '';
export var INVALID_PHONE_NUMBER = '';
export var PHONE_NUMBER_REQUIRE = '';
export var RESEND_IN = "";
export var PASSWORD = "";
export var ENTER_CURRENT_PASSWORD = "";
export var ENTER_NEW_PASSWORD = "";
export var CURRENT_PASSWORD = "";
export var NEW_PASSWORD = "";
export var CONFIRM_PASSWORD = "";
export var ENTER_CONFIRM_PASSWORD = "";
export var FORGOT_PASSWORD = '';
export var RESET_PASSWORD = '';
export var PLEASE_ENTER_CURRENT_PASSWORD = "";
export var PLEASE_ENTER_NEW_PASSWORD = "";
export var NEW_PASSWORD_MIN_LENGTH = "";
export var NEW_PASSWORD_MAX_LENGTH = "";
export var PASSWORD_NOT_MATCHED = "";
export var FORGOT_PASSWORD_TEXT = '';
export var BY_SIGNING_UP = '';
export var CONNECT_INSTANTLY_WITH = '';
export var FACEBOOK = '';
export var GOOGLE = '';
export var GMAIL = '';
export var OR = '';
export var YOUR_PHONE_NUMBER = '';
export var CONFIRM_18YEAR1 = '';
export var CONFIRM_18YEAR2 = '';
export var SEND_OTP = '';
export var VERIFY_MOBILE = '';
export var ENTER_OTP = '';
export var RESEND = '';
export var OTP_SENT_TO = '';
export var HAVE_A_REFERRAL_CODE = '';
export var ENTER_REFERRAL_CODE = '';
export var SKIP_STEP = '';
export var PICK_YOUR_EMAIL = '';
export var ENTER_YOUR_EMAIL = '';
export var ENTER_YOUR_REGISTERED_EMAIL = '';
export var ENTER_PASSWORD = '';
export var EMAIL_USE = '';
export var PICK_YOUR_USERNAME = '';
export var ENTER_YOUR_USERNAME = '';
export var USERNAME_UNIQUE = '';
export var INVALID_EMAIL_ID = '';
export var MORE = '';
export var REFERRAL_CODE_TEXT = '';
export var REFERRAL_CODE_TEXT_COIN = '';
export var VERIFY_MOBILE_TEXT = '';
export var VERIFY_MOBILE_TEXT1 = '';
export var PASSWORD_TEXT = '';
export var PASSWORD_TEXT1 = '';
export var PASSWORD_TEXT2 = '';
export var EMAIL_ADDRESS = '';
export var EMAIL_ADDRESS_TEXT = '';
export var PICK_USER_NAME = '';
export var SELECT_YOUR_LANGUAGE = '';
export var CREATE_YOUR_PASSWORD = '';
export var CREATE_YOUR_PASSWORD_TEXT = '';
export var YOUR_MOBILE_NUMBER = '';
export var YOUR_MOBILE_NUMBER_TEXT = '';
export var OPTIONAL = '';
export var YOUR_PASSWORD = '';
export var YOUR_PASSWORD_TEXT = '';
export var VERIFY_EMAIL = '';
export var FORGOT_YOUR_PASSWORD = '';
export var FORGOT_YOUR_PASSWORD_TEXT = '';
export var RESET_PASSWORD_TEXT = '';
export var EMAIL_SEND_SUCCESS_MESSAGE = '';
export var SIGNUP_INFO = '';
export var SIGNUP_INFO1 = '';
export var SIGN_UP_OR_LOGIN = '';
export var PLEASE_ENTER_A_VALID_REFERRAL_CODE = '';
export var HOW_IT_WORKS = '';

//Drawer
export var TOTAL_BALANCE = '';
export var SWITCH_SPORTS = '';
export var HOME = '';
export var MY_CONTEST = '';
export var MY_WALLET = '';
export var MY_PROFILE = '';
export var REFER_FRIENDS = '';
export var REFER_A_FRIEND = '';
export var REFER_A_FRIEND_C = '';
export var REFER_A_FRIEND_LOWER = '';
export var CHANGE_PASSWORD = '';
export var OTHERS = '';
export var NOTIFICATIONS = '';
export var REDEEM_COINS = '';
export var ORDER_HISTORY = '';
export var RULES_SCORING = '';
export var RULES_AND_SCORING = '';
export var SELECT_NAME_FIRST = '';
export var LOGOUT_MESSAGE = '';
export var HAVE_A_LEAGUE_CODE = '';
export var LOGOUT = '';
export var TAGLINE = '';

//Leaderboard
export var NO_DATA_AVAILABLE = '';
export var GO_TO_MY_CONTEST = '';
export var IN_CASE_AUTO_DOWNLOAD_NOT_START = '';
export var DOWNLOAD_ALL_TEAMS = '';
export var NAME = '';
export var URL_COPIED_TO_CLIPBOARD = '';
export var SUCCESS = '';
export var LEADERBOARD = '';
export var CUSTOM_SCORING_MSG = '';
export var RANK_UPDATED_TEXT = '';

// Fixture, Contest Listing and Detail
export var LINEUP_OUT = '';
export var SCORECARD_NOT_AVAILABLE = '';
export var PLAYING = '';
export var ERROR_MSG = '';
export var NOT_PLAYING = '';
export var ANNOUNCED = '';
export var FIXTURES_HEADER = '';
export var CREATE_A_CONTEST = '';
export var Create_a_Contest = '';
export var JOIN_PRIVATE_CONTEST = '';
export var ENTER_LEAGUE_CODE_TO_JOIN = '';
export var ENTER_LEAGUE_CODE = '';
export var WINNINGS = '';
export var NO_FIXTURES_MSG1 = '';
export var NO_FIXTURES_MSG2 = '';
export var NO_FIXTURES_MSG3 = '';
export var HOW_TO_PLAY_FANTASY = '';
export var GROUP_CONTESTS = '';
export var SORT_CONTESTS = '';
export var GROUP_CONTESTS_MSG = '';
export var LOADING_MSG = '';
export var WIN = '';
export var PRACTICE = '';
export var JOIN = '';
export var ENTRIES = '';
export var FIXTURE = '';
export var MIN = '';
export var MORE_CONTEST = '';
export var LESS_CONTEST = '';
export var ALREADY_JOIN_WITH = '';
export var TEAM = '';
export var TEAMS = '';
export var INFO = '';
export var JOIN_WITH_MAX = '';
export var SUBMIT = '';
export var SUBMIT_PLAYERS_IN_BUDGET = '';
export var SALARY_CAP = '';
export var SCORING_RULES = '';
export var NORMAL = '';
export var FANTASY_ASSIST_LABEL = '';
export var FANTASY_ASSIST = '';
export var FANTASY_ASSIST_SUB1 = '';
export var FANTASY_ASSIST_SUB2 = '';
export var FANTASY_ASSIST_SUB3 = '';
export var FANTASY_ASSIST_SUB4 = '';
export var BONUS = '';
export var CAPTAIN = '';
export var VICE_CAPTAIN = '';
export var ECONOMY_RATE = '';
export var STRIKE_RATE = '';
export var ENTRY = '';
export var FREE_ENTRY = '';
export var FREE = '';
export var WINNERS = ' ';
export var WINNER = ' ';
export var WINNING_DISTRIBUTION = '';
export var DISTRIBUTION = '';
export var MULTI_ENTRY_CONTEST = '';
export var MULTI_ENTRY_DISCRIPTION = ''
export var GUARANTEED_DESCRIPTION = ''
export var GUARANTEED_CONTEST = '';
export var GUARANTEED = '';
export var SINGLE = '';
export var CONFIRM_DESCRIPTION = ''
export var CONFIRM_CONTEST = '';
export var PRIVATE_CONTEST = '';
export var FRIENDS_PLAY_BONUS = '';
export var VS = '';
export var PRIZE_MSG1 = '';
export var PRIZE_MSG2 = '';
export var PRIZE_MSG3 = '';
export var PRIZE_MSG4 = '';
export var GUARANTEED_PRIZE_MSG1 = '';
export var GUARANTEED_PRIZE_MSG2 = '';
export var GUARANTEED_PRIZE_MSG3 = '';
export var GUARANTEED_PRIZE_MSG4 = '';
export var TDS_TEXT = '';
export var IST = '';
export var PULL_DOWN_TO_REFRESH = '';
export var RELEASE_TO_REFRESH = '';
export var THIS_FEATURE_IS_COMING_SOON = '';
export var MIN_CAMEL_CASE = '';
export var DONE = '';
export var ALL = '';
export var NO_TEAM_MSG = '';
export var THIS_CONTEST = '';
export var MY_TEAMS = '';
export var JOIN_NOW = '';
export var CONTEST_JOIN_NOW = '';
export var NO_CONTEST_FOR_FILTER = '';
export var NO_DATA_FOR_FILTER = '';
export var NO_RESULT_FOUND_FILTER_1 = '';
export var NO_CONTEST_FOR_FILTER_1 = '';
export var NO_CONTEST_FOR_FILTER_2 = '';
export var FILTERS = '';
export var MAX_ENTRIES = '';
export var Earn = "";
export var Coin = "";
export var on_your_friends_signup = "";
export var on_your_first_cash_contest = "";
export var MY_TEAM = "";
export var View_All_Players = "";
export var COLLECTION_CONTEST_DISCRIPTION = "";
export var MATCHES_SM = '';
export var COLLECTION = '';
export var MAX_TEAM_FOR_MULTI_ENTRY = '';
export var SINGLE_TEAM_DESCRIPTION = '';
export var TRENDING_DESCRIPTION = '';
export var TRENDING = '';
export var MAX_MULTI_ENTRY_TEAM = '';
export var SALARY_LEFT = '';
export var DELAYED = '';
export var MULTIGAME_TITLE = '';
export var MULTIGAME_DETAIL = '';
export var SELECT_MULTIGAME_CONTEST_TITLE = '';
export var SELECT_MULTIGAME_CONTEST_DETAIL = '';
export var CHOOSE_PLAYER = '';
export var FIXTURE_TAB = '';
export var VIEW_PROOF = '';
export var PROOF = '';
export var NO_QUE_FOR_CATEGORY = '';
export var SWITCH_TO_OTHER_CAT = '';
export var EXCITING_QUE_ON_WAY = '';
export var PICKEM_HTP_LABEL1 = '';
export var PICKEM_HTP_MSG1 = '';
export var PICKEM_HTP_LABEL2 = '';
export var PICKEM_HTP_MSG2 = '';
export var PICKEM_HTP_LABEL3 = '';
export var PICKEM_HTP_MSG3 = '';
export var PARTICIPANTS = '';
export var PICKEM_SHARE_MSG = '';
export var THIS_WILL_BE_UPDATED = '';
export var PEOPLE_JOINED_THIS_CONTEST = '';
export var Use = '';
export var BONUS_CASH_CONTEST_LISTING = '';
export var PLAYED_LAST_MATCH = '';





//Roster and Field View
export var CREDITS_LEFT = '';
export var RESET_ACTION = '';
export var RESET_TEAM = '';
export var SELECTED = '';
export var SELECT = '';
export var FILTER_BY_TEAM = '';
export var FIND_A_PLAYER = '';
export var PLAYER = '';
export var POINTS = '';
export var GETS = ' ';
export var SALARY = '';
export var SALARY_ABR = '';
export var NEXT = '';
export var FANTASY_PTS = '';
export var VALUE = '';
export var FORM = '';
export var GAME_LOG = '';
export var LAST_5_MATCHES = '';
export var DATE = '';
export var FORMAT = '';
export var RUNS = '';
export var WKT = '';
export var E_R = '';
export var S_R = '';
export var PTS = '';
export var ROUND1 = '';
export var ROUND2 = '';
export var ROUND3 = '';
export var RESET = '';
export var RANK = '';
export var ENTER_TAM_NAME = '';
export var CHOOSE_CAPTAIN_VICE_CAPTAIN = '';
export var CHOOSE_CAPTAIN = '';
export var PICK_CAPTAIN = '';
export var CAPTAIN_GETS = '';
export var C = '';
export var VC = '';
export var VICE_CAPTAIN_GETS = '';
export var SORT_BY = '';
export var PLAYERS = '';
export var SUBMIT_LINEUP = '';
export var CONFIRMATION = '';
export var JOINING_CONTEST_WITH = '';
export var ENTRY_FEE = '';
export var Entry_fee = '';
export var PAYABLE_BALANCE = '';
export var JOINING_TEAM_WITH = '';
export var SELECT_TEAM = '';
export var CREATE_NEW_TEAM = '';
export var HAVE_A_PROMO_CODE = '';
export var PROMO_CODE = '';
export var ENTER_PROMO_CODE = '';
export var APPLY = '';
export var JOIN_CONTEST = '';
export var WITH_TEAMS = '';
export var ADD_FUND_JOIN_CONTEST = '';
export var PROMO_TEXT1 = '';
export var PROMO_TEXT2 = '';
export var PROMO_TEXT3 = '';
export var PAYABLE_TOOLTIP1 = '';
export var PAYABLE_TOOLTIP2 = '';
export var MAX = '';
export var JOIN_SUCCESS_TITLE = ''
export var JOIN_SUCCESS_MESSAGE = ''
export var JOIN_MORE = '';
export var JOIN_MORE_POPUP = '';
export var CONTESTS = '';
export var CONTESTS_POPUP = '';
export var SEE_MY_CONTESTS = '';
export var DISMISS = '';
export var OF = '';
export var CONTEST_JOINED = '';
export var TEAMS2 = '';
export var TAB_TO_SEE_FIELD_VIEW = '';
export var MAX_PLAYER_TEAMWISE = '';
export var MAX_PLAYER_TEAMWISE1 = '';
export var VERSES = '';
export var CHOOSE_VICE_CAPTAIN = '';
export var SELECT_MATCH_TEXT = '';
export var CREATE_TEAM = '';
export var PLAYERS_FROM_A_TEAM = '';
export var PICK = '';
export var TEAM_PREVIEW = '';

//Guru Module
export var GENERAT_YOUR_TEAM ='';
export var START_OVER ='';
export var SALARY_REMAINING ='';
export var REMOVE_PLAYER ='';
export var LOCK_PLAYER ='';
export var WANT_MORE_THEN_ONE_TEAM ='';
export var CLICK_HERE ='';
export var REFRESH_TEAM ='';
export var SAVE_THIS_LINEUP ='';
export var GENERAT_LINEUP ='';
export var POWERED_BY ='';
export var EXCLUDED ='';

export var AI_HELP_MESSAGE=''
export var LOCK_PLAYER_TITLE=''
export var LOCK_PLAYER_TITLE_DESCRIPTION=''
export var REMOVE_PLAYER_TITLE=''
export var REMOVE_PLAYER_TITLE_DESCRIPTION=''
export var GENRATE_PLAYER_TITLE=''
export var GENRATE_PLAYER_TITLE_DESCRIPTION=''
export var DONT_SHOW_THIS_AGAIN=''

export var MANAGE_PLAYER_TITLE=''
export var MANAGE_PLAYER_TITLE_DESCRIPTION=''
export var REFRSH_TEAM_TITLE=''
export var REFRSH_TEAM_TITLE_DESCRIPTION=''
export var SAVE_TEAM_TITLE=''
export var SAVE_TEAM_TITLE_DESCRIPTION=''

export var PL_1_TITLE =''
export var PL_1_DESCRIPTION =''
export var PL_2_TITLE =''
export var PL_2_DESCRIPTION =''
export var PL_3_TITLE =''
export var PL_3_DESCRIPTION =''
export var PL_4_TITLE =''
export var PL_4_DESCRIPTION =''
export var PL_5_TITLE =''
export var PL_5_DESCRIPTION =''












//Player Card
export var EXPECTED_SCORE = '';

//My Contest
export var NO_UPCOMING_CONTEST1 = '';
export var NO_UPCOMING_CONTEST2 = '';
export var NO_LIVE_CONTEST1 = '';
export var NO_LIVE_CONTEST2 = '';
export var NO_COMPLETED_CONTEST1 = '';
export var NO_COMPLETED_CONTEST2 = '';
export var GO_TO_LOBBY = '';
export var LIVE = '';
export var UPCOMING = '';
export var ACTIVE = '';
export var COMPLETED = '';
export var IN_PROGRESS = '';
export var STANDINGS = '';
export var SWITCH_TEAM = '';
export var EDIT_TEAM = '';
export var VIEW_TEAM = '';
export var WON = '';
export var YOUR_CURRENT_TEAM = '';
export var SWITCH_WITH = '';
export var NO_UPCOMING_PICKEM1 = '';
export var NO_UPCOMING_PICKEM2 = '';
export var NO_LIVE_PICKEM1 = '';
export var NO_LIVE_PICKEM2 = '';
export var NO_COMPLETED_PICKEM1 = '';
export var NO_COMPLETED_PICKEM2 = '';

//CREATE CONTEST
export var SELECT_LEAGUE = '';
export var MULTI_LINEUP = '';
export var MULTI_LINEUP_ERROR = '';
export var MATCHES = '';
export var Min_team_size = '';
export var Max_team_size = '';
export var Number_of_winners = '';
export var CONTEST_NAME = '';
export var Contest_Name = '';
export var PRIZE_POOL = '';
export var PRIZES = '';
export var Prize_Distribution = '';
export var WINNING = '';
export var TOTAL = '';
export var CREATE_SHARE = '';
export var SELECT_LEAGUE_ERROR = '';
export var SELECT_MATCH = '';
export var SELECT_MINIMUM_TEAM = '';
export var SELECT_MAX_TEAMS = '';
export var MIN_TEAM_CONDITION = '';
export var MAX_TEAM_CONDITION = '';
export var SELECT_ENTRY_FEE = '';
export var SELECT_WINNERS_COUNT = '';
export var SELECT_CONTEST_NAME = '';
export var SELECT_CONTEST_NAME_MIN_CONDITION = '';
export var WINNERS_CONDITION = '';
export var PRIZE_STRUCTURE_ERROR = '';
export var WINNING_PER_EQ100 = '';
export var WINNING_PER_EQLESS100 = '';
export var WINNING_AMT_CONDITION = '';
export var PLEASE_ENTER_AMOUNT = '';
export var ENTERED_AMOUNT_MUST_BE_5 = '';
export var PLEASE_ENTER_ATLEAST_4_DIGIT = '';
export var PLEASE_ENTER_PROMO_CODE = '';

//My Wallet
export var ADD_FUNDS = '';
export var WITHDRAW = '';
export var EARN_COINS = '';
export var EARN_COINS_LOWCASE = '';
export var RECENT_TRANSACTION = '';
export var VIEW_ALL_TRANSACTION = '';
export var TRANSACTION_STATUS_PENDING = '';
export var TRANSACTION_STATUS_SUCCESS = '';
export var TRANSACTION_STATUS_FAILED = '';
export var CREDITS = '';
export var ROLE = '';
export var ACTUAL = '';
export var EVENT = '';
export var MATCH_WISE = '';
export var CLICK_ON_CARD = '';
export var CREDIT = '';
export var credit = '';
export var DEBIT = '';
export var REAL_WINNING_BONUS_AMT = '';
export var TODAY = '';
export var DEPOSIT_BALANCE = '';
export var PAYUMONEY = '';
export var PAYTM = '';
export var GO_CASH = '';
export var MPESA = '';
export var ADD_AMOUNT_TO_WALLET = 'Add Amount to Your Wallet (' + (Utilities.getMasterData() ? Utilities.getMasterData().currency_code : '') + ')';
export var MIN_WITHDRAW_LIMIT = 'The minimum withdrawal limit is ' + (Utilities.getMasterData() ? Utilities.getMasterData().currency_code : '') + ' ';
export var MAX_WITHDRAW_LIMIT = '';
export var BANK_DETAILS = '';
export var PAN_TO_ID = '';
export var AMOUNT = 'Amount (' + (Utilities.getMasterData() ? Utilities.getMasterData().currency_code : '') + ')';
export var PANCARD_PENDING_STATUS = '';
export var PANCARD_REJECTED_STATUS = '';
export var PANCARD_REJECTED_STATUS_MSG = '';
export var FULL_NAME_AS_BANK = '';
export var BANK_NAME = '';
export var ACCOUNT_NUMBER = '';
export var IFSC_CODE = '';
export var PANCARD_NUMBER = '';
export var PANCARD = '';
export var MAX_SIZE_UPLOAD = '';
export var UPLOAD_FORMATS = '';
export var UPDATE_BANK_DETAIL_FROM_PROFILE = '';
export var YOU_CAN_UPDATE_YOUR = '';
export var BANK_DETAIL_FROM_PROFILE = '';
export var UPDATE = '';
export var WITHDRAW_ALERT = '';
export var PROFILE = '';
export var PROFILE_SMALL = '';
export var NOTE = '';
export var WATCH_VIDEO_N_GET = '';
export var ADDED_TO_ACCOUNT = '';
export var THANKYOU = '';
export var WITHDRAW_PROCESS1 = '';
export var WITHDRAW_PROCESS2 = '';
export var OK = '';
export var CANCEL = '';
export var ALERT = '';
export var BANK_TRANSFER = '';
export var Your_payment_successful = "";
export var Your_payment_failed = "";
export var Your_payment_pending = "";
export var Coins_in_your_wallet = "";
export var coins = "";
export var Price = "";
export var Place_order = "";
export var Min_withdraw_amount_is = "";
export var YOUR_WINNINGS = "";
export var MONEY_YOU_WON = "";
export var VERIFY_YOUR_ACCOUNT_TO_ACTIVATE_WITHDRAW_MONEY_SERVICE = "";
export var DEPOSIT = "";
export var VERIFY_YOUR_ACCOUNT = "";
export var VERIFY_GET = "";
export var VERIFICATION_PENDING = "";
export var TRANS_ID = "";
export var STATUS = "";
export var PANCARD_VERIFICATION = "";
export var PHONE_VERIFICATION = "";
export var FIRST_CONTEST = "";
export var FIFTH_CONTEST = "";
export var THENTH_CONTEST = "";
export var NAME_ON_PANCARD = "";
export var VERIFY_PANCARD = "";
export var IMPORTANT_PAN_1 = "";
export var IMPORTANT_PAN_2 = "";
export var IMPORTANT_PAN_3 = "";
export var BankVerification = "";
export var ADD_CASH = "";
export var TOTAL_WINNINGS = "";
export var YOU_CAN_WITHDRAW_ONLY_FROM_WINNING = "";
export var YOUR_WITHDRAWAL_AMOUNT_WILL_BE_CREDITED_TO_THIS_ACCOUNT = "";
export var YOUR_WITHDRAWAL_REQUEST_IS_PENDING = "";
export var VERIFY_BANK_DETAILS = "";
export var SELECT_PAYMENT_METHOD = "";
export var TO_PAY = "";
export var TRANSACTION_STATUS_REJECTED = "";
export var DELETE = "";
export var PENDING_AMOUNT = "";
export var RAISE_ANOTHER_WITHDRAWAL_REQUEST = "";
export var PANCARD_HELP_TEXT = "";
export var INVALID_PROMOCODE = "";
export var PLEASE_ENTER_VALID_PAN_CARD_NUMBER = "";

export var CREDIT_DEBIT_CARD = "";
export var PAYTM_WALLET = "";
export var OTHER_WALLET = "";
export var UPI = "";
export var NET_BANKING = "";
export var VERIFY_YOUR_ACCOUNT_FIRST = "";
export var PAY_WITH_DEBIT_CARD_CREDIT_CARD_UPI_MORE = "";
export var WHERE__HOW_MUCH_SPENT_KNOW_ALL = "";
export var WITHDRAW_YOUR_WINNINGS_IN_YOUR_ACCOUNT = "";
export var YOUR_ACCOUNT_IS_VERIFIED = "";
export var YOUR_ACCOUNT_IS_VERIFIED1 = "";
export var VIEW = "";
export var INSUFFICIENT_BALANCE = "";
export var WITHDRAW_MONEY = "";
export var TRANSACTION_HISTORY = "";
export var TRANSACTIONS = "";
export var NEED_HELP = "";
export var HRS24_SUPPORT = "";
export var ADDITIONAL_INFO = "";
export var ADDITIONAL_PAN_DESC = "";
export var ADDITIONAL_BANK_DESC = "";
export var IMPORTANT_BANK_1 = "";
export var IMPORTANT_BANK_2 = "";
export var IMPORTANT_BANK_3 = "";
export var IMPORTANT_BANK_4 = "";
export var SUFFICIENT_WINNING = "";
export var VALID_UPI_MESSAGE = "";


//Inview on social media
export var INVITE_YOUR_FRIENDS_VIA = '';
export var INVITE_LINK = '';
export var INVITE_FB = '';
export var INVITE_WHATSAPP = '';
export var INVITE_EMAIL = '';
export var INVITE_FRIENDS = '';
export var INVITE_FRIENDS_EARN = '';
export var JOINED = '';
export var CASH = '';
export var MY_REFERRALS = '';
export var BONUS_EARNED = '';
export var CASH_EARNED = '';
export var COINS_EARNED = '';
export var SHARE = '';
export var REFERRAL = '';
export var FRIEND_DEPOSIT = '';
export var ACHIEVEMENTS = '';
export var OF_YOUR_FRIEND_DEPOSIT_MAXIMUM = '';
export var REAL_CASE_ON_YOUR_FRIEND_SIGN_UP = '';
export var INVITE_FRIEND_WIN_REWARD = '';
export var REFER_NOW = '';
export var EDIT_YOUR_CODE_BONUS = '';
export var IT_PAY_TO_HAVE = '';
export var YOU_WILL_EARN_ON_EACH_NEW_SIGN_UP = '';
export var INVALID_ALPHA_NUMERIC_KEY='';
export var OF_YOUR_FRIEND_DEPOSIT = '';
export var EMAIL_VERIFIED = '';
export var FIRST_DEPOSIT = '';
export var PAN_VERIFIED = '';
export var SHARE_REFERRAL_CODE = '';
export var COPY = '';
export var TELL_YOUR_FRIENDS_SIGNUP = ''
export var TELL_YOUR_FRIENDS_JOIN_CONTEST = ''
export var TOP_REFERRERS = '';
export var EARNED = '';
export var TOTAL_EARNED = '';
export var REAL_CASH = '';
export var BONUS_CASH = '';
export var ERNED_FIFITH_REF = '';
export var ERNED_TEHTH_REF = '';
export var ERNED_FIFITHEEN_REF = '';
export var COINS = '';
export var NOT_REFER = '';
export var YOUR_FRIENDS_YET = '';
export var SHARE_NOW = '';
export var MERCHANDISE = '';
export var FREE_CONTEST = '';
export var SHARE_CONTEST_CODE = '';
export var Hi = "";
export var contest_and_win_real_cash = "";
export var League_Url = "";
export var Your_friend_has_shared = "";
export var Your_friend_has_shared_pick = "";
export var WinFantasy = "";
export var Your_friend_has_referred_you = "";
export var Your_Friend = "";
export var has_referred_you_on = "";
export var please_join_and_earn_prizes = "";
export var please_join_and_earn_prizes_text = "";
export var or = "";
export var Join_through_the_following = "";
export var Join_through_the_following_text = "";
export var WHILE_SIGNING_UP = "";
export var YOUR_FRIEND_CONTEST = "";
export var has_referred_you_on_contest = "";
export var please_join_and_earn_prizes_text_contest = "";
export var OR_CONTEST = "";
export var Join_through_the_following_text_contest = "";
export var and_contest_code_contest = "";
export var and_contest_code = "";
export var MEDIUM_ADD = "";
export var Cheers = "";
export var Teams = "";
export var CHEERS_CONTEST = "";
export var TEAMS_CONTEST = "";
export var Link_has_been_copied = "";
export var Promocode_has_been_applied = "";
export var MSZ_COPY_CODE = "";
export var ON_ENTERING_YOUR_FRIENDS_REFERRAL_CODE = "";
export var REAL_CASH_FOR_EVERY_FRIEND_YOU_REFER = "";
export var BONUS_FOR_EVERY_FRIEND_YOU_REFER = "";
export var COINS_FOR_EVERY_FRIEND_YOU_REFER = "";
export var REFER_YOU_AND_EARNED = "";
export var EARN_REAL_CASH_WHEN_YOUR_FRIEND_SIGNS_UP = "";
export var SHARE_YOUR_CODE = "";
export var BRING_YOUR_FRIENDS_AND = "";
export var PLAY_FOR_FREE = "";
export var YOUR_FRIEND = "";
export var THERE_WILL_BE_NO_REFERRAL_SHARING_NW_CONTEST = "";


//Refer Friend 
export var CONGRATULATIONS = "";
export var YOU_GOT_ = "";
export var BONUS_CASH_ON_SIGNINGUP_REFER_CODE = "";
export var BONUS_CASH_ON_SETTING_REF_CODE = "";
export var BONUS_CASH_LOWER = "";

export var REAL_CASH_LOWER = "";
export var COIN_CASH_LOWER = "";
export var EDIT_REFER = "";
export var CODE = "";
export var INVITE_YOUR_FRIEND_BIG_REWARD = "";
export var ON_TRY_COOL_REF_CODE = "";
export var REF_CODE = "";
export var I_DONT_WANT_TO_EDIT = "";
export var YOU_WONT_TO_ABLE_EDIT_THIS_CODE_AGAIN = "";
export var FRIENDS_SIGNUP = "";
export var YOU_GET = "";
export var YOUR_FRIEND_GETS = "";
export var ON_FRIEND_DEPOSIT = "";
export var OF_YOU = "";
export var FRIEND_DEPOSIT_MAXIMUM = "";
export var UPTO = "";
export var LOYALITY_REWARDS = "";
export var FTH_REF = "";
export var TEN_REF = "";
export var FIF_REF = "";
export var GOT_IT = "";
export var REFERRAL_SYSTEM = "";
export var ON_SIGN_UP = "";
export var MORE_REFERRALS = "";
export var ON = "";
export var TOTAL_EARNING = "";
export var LOAD_MORE_RESULTS = "";
export var VIEW_LEADEBOARD=''
export var TOTAL_REFERAL = "";
export var REFERAL_LEADERBOARD = "";
export var REFER_MORE_TEXT=""
export var GET_EXCITING_REWARDS_ON_EVERY_FRIEND_SIGNUP=""




//Profile
export var USER_NAME = '';
export var BASIC_INFO = '';
export var EMAIL = '';
export var DOB = '';
export var GENDER = '';
export var MOBILE_NUMBER = '';
export var ADDRESS = '';
export var EDIT_PROFILE = '';
export var FIRST_NAME = '';
export var LAST_NAME = '';
export var VERIFY = '';
export var VERIFIED = '';
export var SELECT_DOB = '';
export var SELECT_GENDER = '';
export var MALE = '';
export var FEMALE = '';
export var SETREET_ADDRESS = '';
export var COUNTRY = '';
export var STATE = '';
export var CITY = '';
export var PIN_CODE = '';
export var POSTAL_CODE = '';
export var MICR_CODE = '';
export var UPLOAD_PAN_CARD = '';
export var UPLOAD = '';
export var UPLOAD_BANK_DOC = '';
export var UPLOAD_BANK_DOC_DESC = '';
export var BANK_PROFILE_VERIFIED = '';
export var is_invalid = "";
export var Please_select_DOB = "";
export var Please_select_Gender = "";
export var Please_enter_mobile_number = "";
export var Please_upload_ID_card = "";
export var Please_upload_Bank_document = "";
export var VERIFY_YOUR_DETAILS_TO_ENJOY_SEAMLESS_WITHDRAWLS = "";
export var PLAYING_EXPERIENCE = "";
export var PRIMARY_INFO = "";
export var MOBILE = "";
export var PAN = "";
export var BANK = "";
export var EMAIL_VERIFICATION = "";
export var EMAIL_VERIFICATION_TEXT = "";
export var YES = "";
export var NO = "";
export var ARE_YOU_SURE_YOU_WANT_TO_DELETE_BANK_DETAILS = "";
export var EDIT_USERNAME = "";
export var CHECK_AVAILABILITY = "";
export var EDIT_MOBILE_NUMBER = "";
export var EDIT_EMAIL_ADDRESS = "";
export var VERIFY_EMAIL_ADDRESS = "";
export var ADD_AMOUNT = "";
export var ENTER_AMOUNT = "";
export var PICK_BEST_DEAL = "";
export var YOU_WILL_ADDITIONALLY_GET = "";
export var EDIT_BASIC_INFO = "";
export var USERNAME_HAS_BEEN_UPDATED_SUCCUSSFULLY = "";
export var YOUR_MOBILE_NUMBER_HAS_BEEN_UPDATED_SUCCUSSFULLY = "";
export var YOUR_EMAIL_ADDRESS_HAS_BEEN_UPDATED_SUCCUSSFULLY = "";
export var VERIFY_AND_UPDATE = "";
export var WRONG_OTP = "";
export var YOUR_FULL_NAME = "";
export var CANT_EDIT_AFTER_PAN_APPROVAL = "";
export var ARE_YOU_SURE_YOU_WANT_TO_UPDATE_YOUR_PROFILE = "";
export var CONTEST_WON = "";
export var TOTAL_CONTESTS = "";
export var CHOOSE_AVATAR = "";
export var SERIES = "";
export var SOMETHING_ERROR = "";

//Notification
export var DONT_HAVE_ANY = '';
export var NOTIFICATION_YET = '';
export var GO_BACK_TO_LOBBY = '';

//STATIC PAGES
export var TEST = '';
export var T20 = '';
export var ODI = '';
export var NO_SCORING_RULES = '';
export var ABOUT_US_TITLE = '';
export var FAQ_TITLE = '';
export var TANDC_TITLE = '';
export var PRIVACY_POLICY_TITLE = '';
export var AGREE_TO_CONTACTED_BY = "";
export var AND_THEIR_PARTNERS = "";
export var WE_WHOULD_LOVE = "";
export var FOLLOW_US = "";

//Filter
export var Filters = '';

//Page not found
export var I_hereby_confirm = "";
export var and_I_am_not_a = "";
export var Oops = "";
export var Looks_like_you_have = "";
export var Lobby = "";
export var Go_Back_to_Lobby = "";
export var BY_JOINING_THIS_CONTEST = "";

//MyContest
export var You = "";
export var Team = "";
export var Pts = "";
export var Ranks = "";
export var YOUR_PICKS_HGHT = "";

//Contest,Contest Listing & Join private league
export var Entry_for_the_contest = "";
export var Contest = "";
export var Please_Login_Signup_First = "";
export var Please_enter_valid_percentage_value = "";
export var Please_enter_percentage_value_less_100 = "";
export var Your_lineup_will_be_reset = "";
export var Please_enter_a_valid_league_code = "";
export var How_to_Play = "";
export var Join_Private_League = "";
export var Enter_a_League_Code_to_Join = "";
export var Join_private_leagues = "";
export var FANTASY_SPORTS = "";
export var Average = "";
export var Season = "";
export var Last_5_games = "";
export var Graph_data_no_available = "";
export var NO_DATA_FOUND = "";
export var Want_the_best_experience = "";
export var play_fantasy_on_our_Android_app = "";
export var INSTALL = "";
export var CREATE_YOUR_TEAM = "";
export var PAY_AND_JOIN = "";
export var AUTHORISED_TO_USE = "";
export var WINNING_BAL_TO_JOIN = "";
export var BONUS_ALLOWED = "";
export var GO_TO = "";
export var TC = "";
export var AMOUNT_TO_ADD = "";
export var FILTER_MORE_OPTION_TEXT = "";
export var NO_DATA_TO_SHOW = "";
export var MAXIMUM = "";
export var CURRENT = "";

//Item Details
export var Hurry_Only = "";
export var left_in_stock = "";
export var Redeem_NOW = "";
export var Product_Description = "";
export var Coins_Range = "";

//Coin Module
export var WHATSNEW = "";
export var HOW_TO_EARN = "";
export var HOW_TO_EARN_DESC = "";
export var DAILY_CHECKIN = "";
export var SPIN_THE_WHEEL = "";
export var VISIT = "";
export var EVERYDAY = "";
export var DAILY_CHECKIN_DESC = "";
export var GET_REWARDS = "";
export var GET_REWARDS_DESC = "";
export var GET_REWARDS_DESC_COIN = "";
export var GOTIT = "";
export var PREV = "";
export var DAILYCB = "";
export var CTG = "";
export var CTTGC = "";
export var DAY = "";
export var CLAIM_BONUS = "";
export var CLAIM = "";
export var SEE_COIN_TRANS = "";
export var REDEEM = '';
export var REWARDS = '';
export var WORTH = '';
export var REDEEM_W = '';
export var EC_LAUNCHED = '';
export var EC_ONE_PLACE = '';
export var START_EARN = '';
export var YAHOO = '';
export var CR_REAL_CASH = '';
export var CR_BONUS_CASH = '';
export var CR_GIFT = '';
export var REDEEM_COINS_FOR_REWARDS = '';
export var CONVERT_COINS_QUICKLY = '';
export var COINS_BALANCE = '';
export var FEEDBACK = '';
export var FB_HINT = '';
export var GIFT_VOUCHER = '';

export var SPORTS_HUB = '';
export var PICK_GT = '';
export var PICK_HTP = '';
export var VARIETY_GAME = '';
export var START_PLAYING = '';
export var PREDICT_WIN = '';
export var LEARN_MORE = '';
export var HOW_TO_PREDICT = '';
export var VIEW_PREDICTION = '';
export var BE_FIRST = '';
export var PREDICT = '';
export var PREDICTED = '';
export var POOL = '';
export var PLACE_PRE = '';
export var PRE_MSG = '';
export var PRE_MSG2 = '';
export var PICK_MSG = '';
export var YOUR_PRE = '';
export var ENTER_COINS = '';
export var AVAIL_BAL = '';
export var MAKE_PRE = '';
export var MAKE_PICK = '';
export var NEW_PRE = '';
export var CORRECT_ANS = '';
export var EST_WIN = '';
export var YOUR_BET = '';
export var PL_MORE = '';
export var EASY_P = '';
export var NF_SKILL = '';
export var LIVE_ME = '';
export var EXT_Q = '';
export var PB_WIN = '';
export var PWIN_COIN = '';
export var BID = '';
export var P_EXP_MSG = '';
export var EST_WIN_FORMULA = '';
export var REM = '';
export var REMAINING = '';
export var NO_COINS_MSG = '';
export var COINS_WON_MSG = '';
export var COINS_WON_MSG1 = '';
export var OPEN_PREDICTOR = '';
export var CLASSIC_TRIVIA_MSG = '';
export var JUST_GUESS_MSG = '';
export var GAME_FOR_ALL = '';
export var QUE_CATERED_MSG = '';
export var CORRECT_PREDICTIONS = '';
export var COINS_WON = '';
export var THIS_WEEK = '';
export var THIS_MONTH = '';
export var CORRECTED = '';
export var PRIZE = '';
export var YESTERDAY = '';
export var LAST_WEEK = '';
export var LAST_MONTH = '';
export var SPONSOR_BY = '';
export var YOU = '';
export var WIN_EXCITING_PRIZES_LEADERBOARD = '';
export var NOT_ENOUGH_DATA_ON_LEADERBOARD = '';
export var NOT_ENOUGH_DATA = '';
export var ON_LEADERBOARD = '';
export var ARE_YOU_SURE_WANT_PREDICT = '';
export var DISCRIPTION = '';
export var WINNING_REFLECT_SHORTLY = '';
export var PICKEM = '';
export var PICK_YOUR_TEAM = '';
export var YOUR_PICK = '';
export var BET = '';
export var NEW_PICKEM = '';
export var YOU_WON = '';
export var PICK_EXP_MSG = '';
export var REFER_A_FRIEND_AND_GET = '';


//Free To Play Module
export var NO_PRIZES_FOR_THIS_CONTEST = "";
export var LEAGUE_WINNER_WILL_GET_EXCITING_PRIZES = "";
export var VIEW_ALL_PRIZES = "";
export var JOIN_FOR_FREE = "";
export var CONTEST_INFO = "";
export var SPONSER_BY = "";
export var SPONSORED_BY = "";
export var ALL_PRIZES = "";
export var WHAT_IS_HALL_OF_FAME ="";
export var HALL_OF_FAME_JOIN_CONTEST ="";
export var HALL_OF_FAME_IPL_SEASON ="";
export var HALL_OF_FAME_GATHER_POINTS ="";
export var HALL_OF_FAME_WIN_PRIZES ="";
export var HALL_OF_FAME_VIEW_SCHEDULED ="";
export var VIEW_COMPLETED_LEAGUES="";
export var F2P_LEAGUES="";
export var F2P_HALL_OF_FAME_MSG="";
export var GAIN_POINTS="";
export var COMPLETED_LEAGUE="";
export var NO_DATA_VIEW_MESSAGE_COMPLETED_LEAGUE="";
export var NO_DATA_VIEW_MESSAGE_ALL_LEAGUE="";
export var NO_PRIZES_FOR_THIS_LEAGUES="";
export var EDIT_CURRENT_TEAM="";
export var LEAGUE_NO_PRIZE="";
export var LEAGUE_END='';
export var FREE_TO_PLAY='';
export var HOW_TO_PLAY_FREE='';
export var HALL_OF_FAME_JOIN_CONTEST_TEXT ="";
export var PLAY_THE="";
export var JOINED_CAP="";
export var PARTICIPATE_FOR_FREE=""
export var MORE_ABOUT_FREE_TO_PLAY=""
export var CREATE_YOUR_TEAM_AND_JOIN=""
export var BE_PART_OF_HALL_OF_FAME=""
export var JOIN_ALL_MATCHES=""
export var WIN_EXCITING_PRIZES_FREE_TO_PLAY=""
export var PRIZES_WILL_BE_FOR_CONTEST=""


export var YOU_HAVE_WON = '';
export var SURPRISE_WAY=""
export var JOIN_MORE_SCRATCH=""
export var EXP_PAYMENT_IN=""
export var SWIPE_BACK=""
export var FROM=""
export var BETTER_LUCK=""











//Pickem
export var EDIT_PICKS_DEADLINE = '';
export var REMOVE_PICK = '';
export var PICK_NO_FIXTURES_MSG = '';
export var PICK_NO_UPCOMING_CONTEST1 = '';
export var PICK_UPCOMING_CONTEST2 = '';
export var PICK_NO_LIVE_CONTEST1 = '';
export var PICK_NO_LIVE_CONTEST2 = '';
export var PICK_NO_COMPLETED_CONTEST1 = '';
export var PICK_NO_COMPLETED_CONTEST2 = '';
export var WEEKLY = '';
export var MONTHLY = '';
export var WEEKS = '';
export var MONTHS = '';
export var YOUR_STATS = '';
export var THIS_WEEK_RANK = '';
export var THIS_MONTH_RANK = '';
export var PICK_LOBBY_TEXT = '';

// Esport
export var DONT_SHOW_ME_AGAIN = '';
export var CUSTOMIZE_REFERRAL_CODE = '';

//AFFILIATE
export var BECOME_AFFILIATE = "";
export var AFFILIATE_PROGRAM = "";
export var AFFILIATE_TAGLINE1 = "";
export var AFFILIATE_TAGLINE2 = "";
export var INDUSTRY_LEADING = "";
export var INDUSTRY_LEADING_TAGLINE = "";
export var PROMOTION_EASY = "";
export var PROMOTION_EASY_TAGLINE = "";
export var POWERFUL_TRACKING = "";
export var POWERFUL_TRACKING_TAGLINE = "";
export var DETAILS = "";
export var USER = "";
export var AFF_AMOUNT = "";
export var COMMISION = "";
export var SIGNUP = "";
export var AMOUNT_DEPOSITED = "";
export var BONUS_ON = "";
export var COMMISION_ON = "";
export var TOTAL_SIGNUP = "";
export var DEPOSITED_AMOUNT = "";
export var COMMISION_TILL_DATE = "";
export var COMMISION_DUE = "";
export var DEPOSITE = "";
export var REQ_PENDING = "";
export var REQ_CANCELED = "";

// coin entry
export var ADD_COIN_AND_JOIN_CONTEST = '';
export var PAYABLE_COINS_BAL = '';

//Coin Module
export var BUY_COINS = '';
export var AMOUNT_DEDUCTION_MSG = '';
export var BUY_CONFIRMATION = '';
export var BUY = '';

//Team Compare
export var SEE = '';
export var ON_FIELDVIEW = '';
export var OPP_LEAD_BY = '';
export var YOU_LEAD_BY = '';
export var COMMON_PLAYERS = '';
export var OPP_CVC_LEAD_BY = '';
export var YOU_CVC_LEAD_BY = '';
export var ALL_PLAYERS = '';
export var TOTAL_POINTS = '';
export var YOU_CANT_COMPARE_YOU_OWN_TEAMS = '';
export var COMPARE_TEAMS = '';
export var PTS1 = '';

//Reverse Fantasy
export var REVERSE_FANTASY = '';
export var REVERSE_FANTASY_JUST_OPP = '';
export var REVERSE_FANTASY_TEAMS = '';
export var FEEL_THE_THRILL_OF_REVERSE = '';
export var REVERSE_ALERT_MSG = '';
export var OKAY = '';
export var WHATS_REVERSE_FANTASY = '';
export var WRF_HEAD1 = '';
export var WRF_DESC1 = '';
export var WRF_DESC2 = '';
export var WRF_HEAD2 = '';
export var WRF_DESC3 = '';
export var WRF_HEAD3 = '';
export var SEE_REVERSE_FANTASY = '';
export var RULES = '';
export var RF_TOOLTIP_TEXT = '';
export var YOU_CAN_CREATE_ONLY_10TEAMS = '';
export var CLASSIC_FANTASY = '';


function translateHomeData() {
    let objHome = transtale('home');
    FIXTURES_HEADER1 = objHome.FIXTURES_HEADER1;
    FIXTURES_HEADER2 = objHome.FIXTURES_HEADER2;
    DOWNLOAD_NOW = objHome.DOWNLOAD_NOW;
    PLAY_NOW = objHome.PLAY_NOW;
    PLAY = objHome.PLAY;
    ABOUT_US = objHome.ABOUT_US;
    TERMS_CONDITION = objHome.TERMS_CONDITION;
    PRIVACY_POLICY = objHome.PRIVACY_POLICY;
    THE_GAME = objHome.THE_GAME;
    HOW_TO_PLAY = objHome.HOW_TO_PLAY;
    HOW_TO_PLAY_FANTASY_SPORTS = objHome.HOW_TO_PLAY_FANTASY_SPORTS;
    RULES_SCORING_SYSTEM = objHome.RULES_SCORING_SYSTEM;
    SUPPORT = objHome.SUPPORT;
    FAQS = objHome.FAQS;
    CONTACT_US = objHome.CONTACT_US;
    REFUND_POLICY = objHome.REFUND_POLICY;
    OFFERS = objHome.OFFERS;
    LEGALITY = objHome.LEGALITY;
    CONTACT_US_EMAIL = objHome.CONTACT_US_EMAIL;
    CONTACT_US_PHONE = objHome.CONTACT_US_PHONE;
    DOWNLOAD_APP = objHome.DOWNLOAD_APP;
    DOWNLOAD_MOBILE_APP = objHome.DOWNLOAD_MOBILE_APP;
    MOBILE_APP_DESC = objHome.MOBILE_APP_DESC;
    DOWNLOAD_MEESAGE=objHome.DOWNLOAD_MEESAGE;
    ENTER_MOBILE_NUMBER = objHome.ENTER_MOBILE_NUMBER;
    ENTER_MOBILE = objHome.ENTER_MOBILE;
    GET_LINK_NOW = objHome.GET_LINK_NOW;
    CHANGE_LANGUAGE = objHome.CHANGE_LANGUAGE
    DISCLAIMER_LINE1 = objHome.DISCLAIMER_LINE1;
    DISCLAIMER_LINE_SUB_TITLE1 = objHome.DISCLAIMER_LINE_SUB_TITLE1;
    DISCLAIMER_LINE_SUB_TITLE2 = objHome.DISCLAIMER_LINE_SUB_TITLE2;
    DISCLAIMER_LINE_SUB_TITLE3 = objHome.DISCLAIMER_LINE_SUB_TITLE3;
    DISCLAIMER_LINE_SUB_TITLE4 = objHome.DISCLAIMER_LINE_SUB_TITLE4;
    DISCLAIMER_LINE_SUB_TITLE5 = objHome.DISCLAIMER_LINE_SUB_TITLE5;
    DISCLAIMER_LINE2 = objHome.DISCLAIMER_LINE2;
    DISCLAIMER_LINE3 = objHome.DISCLAIMER_LINE3;
    DISCLAIMER_LINE4 = objHome.DISCLAIMER_LINE4;
}
function translateFTPData() {
    let obj = transtale('free_to_play');
    NO_PRIZES_FOR_THIS_CONTEST = obj.NO_PRIZES_FOR_THIS_CONTEST;
    LEAGUE_WINNER_WILL_GET_EXCITING_PRIZES = obj.LEAGUE_WINNER_WILL_GET_EXCITING_PRIZES;
    VIEW_ALL_PRIZES = obj.VIEW_ALL_PRIZES;
    JOIN_FOR_FREE = obj.JOIN_FOR_FREE;
    CONTEST_INFO = obj.CONTEST_INFO;
    SPONSER_BY = obj.SPONSER_BY;
    SPONSORED_BY = obj.SPONSORED_BY;
    ALL_PRIZES = obj.ALL_PRIZES;
    WHAT_IS_HALL_OF_FAME =obj.WHAT_IS_HALL_OF_FAME;
    HALL_OF_FAME_JOIN_CONTEST=obj.HALL_OF_FAME_JOIN_CONTEST
    HALL_OF_FAME_JOIN_CONTEST_TEXT=obj.HALL_OF_FAME_JOIN_CONTEST_TEXT
    HALL_OF_FAME_IPL_SEASON =obj.HALL_OF_FAME_IPL_SEASON;
    HALL_OF_FAME_GATHER_POINTS =obj.HALL_OF_FAME_GATHER_POINTS;
    HALL_OF_FAME_WIN_PRIZES =obj.HALL_OF_FAME_WIN_PRIZES;
    HALL_OF_FAME_VIEW_SCHEDULED =obj.HALL_OF_FAME_VIEW_SCHEDULED;
    VIEW_COMPLETED_LEAGUES=obj.VIEW_COMPLETED_LEAGUES;
    F2P_LEAGUES=obj.F2P_LEAGUES;
    F2P_HALL_OF_FAME_MSG=obj.F2P_HALL_OF_FAME_MSG;
    GAIN_POINTS=obj.GAIN_POINTS;
    COMPLETED_LEAGUE=obj.COMPLETED_LEAGUE;
    NO_DATA_VIEW_MESSAGE_COMPLETED_LEAGUE=obj.NO_DATA_VIEW_MESSAGE_COMPLETED_LEAGUE;
    NO_DATA_VIEW_MESSAGE_ALL_LEAGUE=obj.NO_DATA_VIEW_MESSAGE_ALL_LEAGUE;
    NO_PRIZES_FOR_THIS_LEAGUES=obj.NO_PRIZES_FOR_THIS_LEAGUES;
    EDIT_CURRENT_TEAM=obj.EDIT_CURRENT_TEAM;
    LEAGUE_NO_PRIZE=obj.LEAGUE_NO_PRIZE;
    LEAGUE_END=obj.LEAGUE_END;
    FREE_TO_PLAY=obj.FREE_TO_PLAY;
    HOW_TO_PLAY_FREE=obj.HOW_TO_PLAY_FREE;
    PLAY_THE=obj.PLAY_THE;
    JOINED_CAP=obj.JOINED_CAP;
    PARTICIPATE_FOR_FREE=obj.PARTICIPATE_FOR_FREE;
    MORE_ABOUT_FREE_TO_PLAY=obj.MORE_ABOUT_FREE_TO_PLAY;
    CREATE_YOUR_TEAM_AND_JOIN=obj.CREATE_YOUR_TEAM_AND_JOIN;
    BE_PART_OF_HALL_OF_FAME=obj.BE_PART_OF_HALL_OF_FAME;
    JOIN_ALL_MATCHES=obj.JOIN_ALL_MATCHES;
    WIN_EXCITING_PRIZES_FREE_TO_PLAY=obj.WIN_EXCITING_PRIZES_FREE_TO_PLAY;
    PRIZES_WILL_BE_FOR_CONTEST=obj.PRIZES_WILL_BE_FOR_CONTEST;


}
function translateSportsHub() {
    let objSportsHub = transtale('sports_hub');
    SPORTS_HUB = objSportsHub.SPORTS_HUB;
    PICK_GT = objSportsHub.PICK_GT;
    PICK_HTP = objSportsHub.PICK_HTP;
    EDIT_PICKS_DEADLINE = objSportsHub.EDIT_PICKS_DEADLINE;
    REMOVE_PICK = objSportsHub.REMOVE_PICK;
    PICK_NO_FIXTURES_MSG = objSportsHub.PICK_NO_FIXTURES_MSG;
    PICK_NO_UPCOMING_CONTEST1 = objSportsHub.PICK_NO_UPCOMING_CONTEST1;
    PICK_UPCOMING_CONTEST2 = objSportsHub.PICK_UPCOMING_CONTEST2;
    PICK_NO_LIVE_CONTEST1 = objSportsHub.PICK_NO_LIVE_CONTEST1;
    PICK_NO_LIVE_CONTEST2 = objSportsHub.PICK_NO_LIVE_CONTEST2;
    PICK_NO_COMPLETED_CONTEST1 = objSportsHub.PICK_NO_COMPLETED_CONTEST1;
    PICK_NO_COMPLETED_CONTEST2 = objSportsHub.PICK_NO_COMPLETED_CONTEST2;
    WEEKLY = objSportsHub.WEEKLY;
    MONTHLY = objSportsHub.MONTHLY;
    WEEKS = objSportsHub.WEEKS;
    MONTHS = objSportsHub.MONTHS;
    YOUR_STATS = objSportsHub.YOUR_STATS;
    THIS_WEEK_RANK = objSportsHub.THIS_WEEK_RANK;
    THIS_MONTH_RANK = objSportsHub.THIS_MONTH_RANK;
    PICK_LOBBY_TEXT = objSportsHub.PICK_LOBBY_TEXT;

    
     
    VARIETY_GAME = objSportsHub.VARIETY_GAME;
    START_PLAYING = objSportsHub.START_PLAYING;
    PREDICT_WIN = objSportsHub.PREDICT_WIN;
    LEARN_MORE = objSportsHub.LEARN_MORE;
    HOW_TO_PREDICT = objSportsHub.HOW_TO_PREDICT;
    VIEW_PREDICTION = objSportsHub.VIEW_PREDICTION;
    BE_FIRST = objSportsHub.BE_FIRST;
    PREDICT = objSportsHub.PREDICT;
    PREDICTED = objSportsHub.PREDICTED;
    POOL = objSportsHub.POOL;
    PLACE_PRE = objSportsHub.PLACE_PRE;
    PRE_MSG = objSportsHub.PRE_MSG;
    PRE_MSG2 = objSportsHub.PRE_MSG2;
    PICK_MSG = objSportsHub.PICK_MSG;
    YOUR_PRE = objSportsHub.YOUR_PRE;
    ENTER_COINS = objSportsHub.ENTER_COINS;
    AVAIL_BAL = objSportsHub.AVAIL_BAL;
    MAKE_PRE = objSportsHub.MAKE_PRE;
    MAKE_PICK = objSportsHub.MAKE_PICK;
    NEW_PRE = objSportsHub.NEW_PRE;
    CORRECT_ANS = objSportsHub.CORRECT_ANS;
    EST_WIN = objSportsHub.EST_WIN;
    YOUR_BET = objSportsHub.YOUR_BET;
    PL_MORE = objSportsHub.PL_MORE;
    EASY_P = objSportsHub.EASY_P;
    NF_SKILL = objSportsHub.NF_SKILL;
    LIVE_ME = objSportsHub.LIVE_ME;
    EXT_Q = objSportsHub.EXT_Q;
    PB_WIN = objSportsHub.PB_WIN;
    PWIN_COIN = objSportsHub.PWIN_COIN;
    BID = objSportsHub.BID;
    P_EXP_MSG = objSportsHub.P_EXP_MSG;
    EST_WIN_FORMULA = objSportsHub.EST_WIN_FORMULA;
    REM = objSportsHub.REM;
    REMAINING = objSportsHub.REMAINING;
    NO_COINS_MSG = objSportsHub.NO_COINS_MSG;
    COINS_WON_MSG = objSportsHub.COINS_WON_MSG;
    COINS_WON_MSG1 = objSportsHub.COINS_WON_MSG1;
    OPEN_PREDICTOR = objSportsHub.OPEN_PREDICTOR;
    CLASSIC_TRIVIA_MSG = objSportsHub.CLASSIC_TRIVIA_MSG;
    JUST_GUESS_MSG = objSportsHub.JUST_GUESS_MSG;
    GAME_FOR_ALL = objSportsHub.GAME_FOR_ALL;
    QUE_CATERED_MSG = objSportsHub.QUE_CATERED_MSG;
    COINS_WON = objSportsHub.COINS_WON;
    THIS_WEEK = objSportsHub.THIS_WEEK;
    THIS_MONTH = objSportsHub.THIS_MONTH;
    CORRECTED = objSportsHub.CORRECTED;
    PRIZE = objSportsHub.PRIZE;
    YESTERDAY = objSportsHub.YESTERDAY;
    LAST_WEEK = objSportsHub.LAST_WEEK;
    LAST_MONTH = objSportsHub.LAST_MONTH;
    SPONSOR_BY = objSportsHub.SPONSOR_BY;
    YOU = objSportsHub.YOU;
    WIN_EXCITING_PRIZES_LEADERBOARD = objSportsHub.WIN_EXCITING_PRIZES_LEADERBOARD;
    NOT_ENOUGH_DATA_ON_LEADERBOARD = objSportsHub.NOT_ENOUGH_DATA_ON_LEADERBOARD;
    NOT_ENOUGH_DATA = objSportsHub.NOT_ENOUGH_DATA;
    ON_LEADERBOARD = objSportsHub.ON_LEADERBOARD;
    ARE_YOU_SURE_WANT_PREDICT = objSportsHub.ARE_YOU_SURE_WANT_PREDICT;
    DISCRIPTION = objSportsHub.DISCRIPTION;
    WINNING_REFLECT_SHORTLY = objSportsHub.WINNING_REFLECT_SHORTLY;
    PICKEM = objSportsHub.PICKEM;
    PICK_YOUR_TEAM = objSportsHub.PICK_YOUR_TEAM;
    YOUR_PICK = objSportsHub.YOUR_PICK;
    BET = objSportsHub.BET;
    NEW_PICKEM = objSportsHub.NEW_PICKEM;
    YOU_WON = objSportsHub.YOU_WON;
    PICK_EXP_MSG = objSportsHub.PICK_EXP_MSG;
    REFER_A_FRIEND_AND_GET = objSportsHub.REFER_A_FRIEND_AND_GET;
}

function translateCoinData() {
    let objCoins = transtale('coins');
    WHATSNEW = objCoins.WHATSNEW;
    HOW_TO_EARN = objCoins.HOW_TO_EARN;
    HOW_TO_EARN_DESC = objCoins.HOW_TO_EARN_DESC;
    DAILY_CHECKIN = objCoins.DAILY_CHECKIN;
    SPIN_THE_WHEEL = objCoins.SPIN_THE_WHEEL;
    VISIT = objCoins.VISIT;
    EVERYDAY = objCoins.EVERYDAY;
    DAILY_CHECKIN_DESC = objCoins.DAILY_CHECKIN_DESC;
    GET_REWARDS = objCoins.GET_REWARDS;
    GET_REWARDS_DESC = objCoins.GET_REWARDS_DESC;
    GET_REWARDS_DESC_COIN = objCoins.GET_REWARDS_DESC_COIN;
    GOTIT = objCoins.GOTIT;
    PREV = objCoins.PREV;
    DAILYCB = objCoins.DAILYCB;
    CTG = objCoins.CTG;
    CTTGC = objCoins.CTTGC;
    DAY = objCoins.DAY;
    CLAIM_BONUS = objCoins.CLAIM_BONUS;
    CLAIM = objCoins.CLAIM;
    SEE_COIN_TRANS = objCoins.SEE_COIN_TRANS;
    REDEEM = objCoins.REDEEM;
    REWARDS = objCoins.REWARDS;
    WORTH = objCoins.WORTH;
    REDEEM_W = objCoins.REDEEM_W;
    EC_LAUNCHED = objCoins.EC_LAUNCHED;
    EC_ONE_PLACE = objCoins.EC_ONE_PLACE;
    START_EARN = objCoins.START_EARN;
    YAHOO = objCoins.YAHOO;
    CR_REAL_CASH = objCoins.CR_REAL_CASH;
    CR_BONUS_CASH = objCoins.CR_BONUS_CASH;
    CR_GIFT = objCoins.CR_GIFT;
    REDEEM_COINS_FOR_REWARDS = objCoins.REDEEM_COINS_FOR_REWARDS;
    CONVERT_COINS_QUICKLY = objCoins.CONVERT_COINS_QUICKLY;
    COINS_BALANCE = objCoins.COINS_BALANCE;
    FEEDBACK = objCoins.FEEDBACK;
    FB_HINT = objCoins.FB_HINT;
    GIFT_VOUCHER = objCoins.GIFT_VOUCHER;
    YOU_HAVE_WON = objCoins.YOU_HAVE_WON;
    SURPRISE_WAY = objCoins.SURPRISE_WAY;
    JOIN_MORE_SCRATCH = objCoins.JOIN_MORE_SCRATCH;
    EXP_PAYMENT_IN = objCoins.EXP_PAYMENT_IN;
    SWIPE_BACK = objCoins.SWIPE_BACK;
    FROM = objCoins.FROM;
    BETTER_LUCK = objCoins.BETTER_LUCK;
}

function translateOnboardigData() {
    let obj = transtale('onboarding');
    SIGN_UP = obj.SIGN_UP;
    LOGIN = obj.LOGIN;
    GET = obj.GET;
    INSTANTLY = obj.INSTANTLY;
    ON_EVERY_SIGNUP = obj.ON_EVERY_SIGNUP;
    T_N_C_APPLY = obj.T_N_C_APPLY;
    INVALID_PHONE_NUMBER = obj.INVALID_PHONE_NUMBER;
    PHONE_NUMBER_REQUIRE = obj.PHONE_NUMBER_REQUIRE;
    RESEND_IN = obj.RESEND_IN;
    PASSWORD = obj.PASSWORD;
    ENTER_CURRENT_PASSWORD = obj.ENTER_CURRENT_PASSWORD;
    ENTER_NEW_PASSWORD = obj.ENTER_NEW_PASSWORD;
    CURRENT_PASSWORD = obj.CURRENT_PASSWORD;
    NEW_PASSWORD = obj.NEW_PASSWORD;
    CONFIRM_PASSWORD = obj.CONFIRM_PASSWORD;
    ENTER_CONFIRM_PASSWORD = obj.ENTER_CONFIRM_PASSWORD;
    FORGOT_PASSWORD = obj.FORGOT_PASSWORD;
    RESET_PASSWORD = obj.RESET_PASSWORD;
    PLEASE_ENTER_CURRENT_PASSWORD = obj.PLEASE_ENTER_CURRENT_PASSWORD;
    PLEASE_ENTER_NEW_PASSWORD = obj.PLEASE_ENTER_NEW_PASSWORD;
    NEW_PASSWORD_MIN_LENGTH = obj.NEW_PASSWORD_MIN_LENGTH;
    NEW_PASSWORD_MAX_LENGTH = obj.NEW_PASSWORD_MAX_LENGTH;
    PASSWORD_NOT_MATCHED = obj.PASSWORD_NOT_MATCHED;
    FORGOT_PASSWORD_TEXT = obj.FORGOT_PASSWORD_TEXT;
    BY_SIGNING_UP = obj.BY_SIGNING_UP;
    CONNECT_INSTANTLY_WITH = obj.CONNECT_INSTANTLY_WITH;
    FACEBOOK = obj.FACEBOOK;
    GOOGLE = obj.GOOGLE;
    GMAIL = obj.GMAIL;
    OR = obj.OR;
    YOUR_PHONE_NUMBER = obj.YOUR_PHONE_NUMBER;
    CONFIRM_18YEAR1 = obj.CONFIRM_18YEAR1;
    CONFIRM_18YEAR2 = obj.CONFIRM_18YEAR2;
    SEND_OTP = obj.SEND_OTP;
    VERIFY_MOBILE = obj.VERIFY_MOBILE;
    ENTER_OTP = obj.ENTER_OTP;
    RESEND = obj.RESEND;
    OTP_SENT_TO = obj.OTP_SENT_TO;
    HAVE_A_REFERRAL_CODE = obj.HAVE_A_REFERRAL_CODE;
    ENTER_REFERRAL_CODE = obj.ENTER_REFERRAL_CODE;
    SKIP_STEP = obj.SKIP_STEP;
    PICK_YOUR_EMAIL = obj.PICK_YOUR_EMAIL;
    ENTER_YOUR_EMAIL = obj.ENTER_YOUR_EMAIL;
    ENTER_YOUR_REGISTERED_EMAIL = obj.ENTER_YOUR_REGISTERED_EMAIL;
    ENTER_PASSWORD = obj.ENTER_PASSWORD;
    EMAIL_USE = obj.EMAIL_USE;
    PICK_YOUR_USERNAME = obj.PICK_YOUR_USERNAME;
    ENTER_YOUR_USERNAME = obj.ENTER_YOUR_USERNAME;
    USERNAME_UNIQUE = obj.USERNAME_UNIQUE;
    INVALID_EMAIL_ID = obj.INVALID_EMAIL_ID;
    MORE = obj.MORE;
    VERIFY_EMAIL = obj.VERIFY_EMAIL;
    REFERRAL_CODE_TEXT = obj.REFERRAL_CODE_TEXT;
    REFERRAL_CODE_TEXT_COIN = obj.REFERRAL_CODE_TEXT_COIN;
    VERIFY_MOBILE_TEXT = obj.VERIFY_MOBILE_TEXT;
    VERIFY_MOBILE_TEXT1 = obj.VERIFY_MOBILE_TEXT1;
    PASSWORD_TEXT = obj.PASSWORD_TEXT;
    PASSWORD_TEXT1 = obj.PASSWORD_TEXT1;
    PASSWORD_TEXT2 = obj.PASSWORD_TEXT2;
    EMAIL_ADDRESS = obj.EMAIL_ADDRESS;
    EMAIL_ADDRESS_TEXT = obj.EMAIL_ADDRESS_TEXT;
    PICK_USER_NAME = obj.PICK_USER_NAME;
    SELECT_YOUR_LANGUAGE = obj.SELECT_YOUR_LANGUAGE;
    CREATE_YOUR_PASSWORD = obj.CREATE_YOUR_PASSWORD;
    CREATE_YOUR_PASSWORD_TEXT = obj.CREATE_YOUR_PASSWORD_TEXT;
    YOUR_MOBILE_NUMBER = obj.YOUR_MOBILE_NUMBER;
    YOUR_MOBILE_NUMBER_TEXT = obj.YOUR_MOBILE_NUMBER_TEXT;
    OPTIONAL = obj.OPTIONAL;
    YOUR_PASSWORD = obj.YOUR_PASSWORD;
    YOUR_PASSWORD_TEXT = obj.YOUR_PASSWORD_TEXT;
    FORGOT_YOUR_PASSWORD = obj.FORGOT_YOUR_PASSWORD;
    FORGOT_YOUR_PASSWORD_TEXT = obj.FORGOT_YOUR_PASSWORD_TEXT;
    RESET_PASSWORD_TEXT = obj.RESET_PASSWORD_TEXT;
    EMAIL_SEND_SUCCESS_MESSAGE = obj.EMAIL_SEND_SUCCESS_MESSAGE;
    SIGNUP_INFO = obj.SIGNUP_INFO;
    SIGNUP_INFO1 = obj.SIGNUP_INFO1;
    SIGN_UP_OR_LOGIN = obj.SIGN_UP_OR_LOGIN;
    PLEASE_ENTER_A_VALID_REFERRAL_CODE = obj.PLEASE_ENTER_A_VALID_REFERRAL_CODE;

}

function translateDrawer() {
    let obj = transtale('drawer');
    TOTAL_BALANCE = obj.TOTAL_BALANCE;
    SWITCH_SPORTS = obj.SWITCH_SPORTS;
    HOME = obj.HOME;
    MY_CONTEST = obj.MY_CONTEST;
    MY_WALLET = obj.MY_WALLET;
    MY_PROFILE = obj.MY_PROFILE;
    REFER_FRIENDS = obj.REFER_FRIENDS;
    REFER_A_FRIEND = obj.REFER_A_FRIEND;
    REFER_A_FRIEND_C = obj.REFER_A_FRIEND_C;
    REFER_A_FRIEND_LOWER = obj.REFER_A_FRIEND_LOWER;
    CHANGE_PASSWORD = obj.CHANGE_PASSWORD;
    OTHERS = obj.OTHERS;
    NOTIFICATIONS = obj.NOTIFICATIONS;
    REDEEM_COINS = obj.REDEEM_COINS;
    ORDER_HISTORY = obj.ORDER_HISTORY;
    RULES_SCORING = obj.RULES_SCORING;
    RULES_AND_SCORING = obj.RULES_AND_SCORING;
    SELECT_NAME_FIRST = obj.SELECT_NAME_FIRST;
    LOGOUT_MESSAGE = obj.LOGOUT_MESSAGE;
    HAVE_A_LEAGUE_CODE = obj.HAVE_A_LEAGUE_CODE;
    LOGOUT = obj.LOGOUT;
    TAGLINE = obj.TAGLINE;
}

function translateLeaderboard() {
    let obj = transtale('leaderboard');
    NO_DATA_AVAILABLE = obj.NO_DATA_AVAILABLE;
    GO_TO_MY_CONTEST = obj.GO_TO_MY_CONTEST;
    IN_CASE_AUTO_DOWNLOAD_NOT_START = obj.IN_CASE_AUTO_DOWNLOAD_NOT_START;
    DOWNLOAD_ALL_TEAMS = obj.DOWNLOAD_ALL_TEAMS;
    NAME = obj.NAME;
    URL_COPIED_TO_CLIPBOARD = obj.URL_COPIED_TO_CLIPBOARD;
    SUCCESS = obj.SUCCESS;
    LEADERBOARD = obj.LEADERBOARD;
    CUSTOM_SCORING_MSG = obj.CUSTOM_SCORING_MSG;
    RANK_UPDATED_TEXT=obj.RANK_UPDATED_TEXT;
}

function translateRules() {
    let obj = transtale('rules');
    FANTASY_ASSIST = obj.FANTASY_ASSIST;
    FANTASY_ASSIST_LABEL = obj.FANTASY_ASSIST_LABEL;
    FANTASY_ASSIST_SUB1 = obj.FANTASY_ASSIST_SUB1;
    FANTASY_ASSIST_SUB2 = obj.FANTASY_ASSIST_SUB2;
    FANTASY_ASSIST_SUB3 = obj.FANTASY_ASSIST_SUB3;
    FANTASY_ASSIST_SUB4 = obj.FANTASY_ASSIST_SUB4;
}

function translateContestData() {
    let obj = transtale('fixturecontest');
    LINEUP_OUT = obj.LINEUP_OUT;
    SCORECARD_NOT_AVAILABLE = obj.SCORECARD_NOT_AVAILABLE;
    PLAYING = obj.PLAYING;
    ERROR_MSG = obj.ERROR_MSG;
    NOT_PLAYING = obj.NOT_PLAYING;
    ANNOUNCED = obj.ANNOUNCED;
    ROLE = obj.ROLE;
    ACTUAL = obj.ACTUAL;
    EVENT = obj.EVENT;
    MATCH_WISE = obj.MATCH_WISE;
    CLICK_ON_CARD = obj.CLICK_ON_CARD;
    FIXTURES_HEADER = obj.FIXTURES_HEADER;
    CREATE_A_CONTEST = obj.CREATE_A_CONTEST;
    Create_a_Contest = obj.Create_a_Contest;
    JOIN_PRIVATE_CONTEST = obj.JOIN_PRIVATE_CONTEST;
    ENTER_LEAGUE_CODE_TO_JOIN = obj.ENTER_LEAGUE_CODE_TO_JOIN;
    ENTER_LEAGUE_CODE = obj.ENTER_LEAGUE_CODE;
    WINNINGS = obj.WINNINGS;
    NO_FIXTURES_MSG1 = obj.NO_FIXTURES_MSG1;
    NO_FIXTURES_MSG2 = obj.NO_FIXTURES_MSG2;
    NO_FIXTURES_MSG3 = obj.NO_FIXTURES_MSG3;
    HOW_TO_PLAY_FANTASY = obj.HOW_TO_PLAY_FANTASY;
    GROUP_CONTESTS = obj.GROUP_CONTESTS;
    SORT_CONTESTS = obj.SORT_CONTESTS;
    GROUP_CONTESTS_MSG = obj.GROUP_CONTESTS_MSG;
    LOADING_MSG = obj.LOADING_MSG;
    WIN = obj.WIN;
    PRACTICE = obj.PRACTICE;
    JOIN = obj.JOIN;
    ENTRIES = obj.ENTRIES;
    MIN = obj.MIN;
    MORE_CONTEST = obj.MORE_CONTEST;
    LESS_CONTEST = obj.LESS_CONTEST;
    ALREADY_JOIN_WITH = obj.ALREADY_JOIN_WITH;
    TEAM = obj.TEAM;
    TEAMS = obj.TEAMS;
    INFO = obj.INFO;
    JOIN_WITH_MAX = obj.JOIN_WITH_MAX;
    SUBMIT = obj.SUBMIT;
    SUBMIT_PLAYERS_IN_BUDGET = obj.SUBMIT_PLAYERS_IN_BUDGET;
    SALARY_CAP = obj.SALARY_CAP;
    SCORING_RULES = obj.SCORING_RULES;
    NORMAL = obj.NORMAL;
    BONUS = obj.BONUS;
    CAPTAIN = obj.CAPTAIN;
    VICE_CAPTAIN = obj.VICE_CAPTAIN;
    ECONOMY_RATE = obj.ECONOMY_RATE;
    STRIKE_RATE = obj.STRIKE_RATE;
    ENTRY = obj.ENTRY;
    FREE_ENTRY = obj.FREE_ENTRY;
    FREE = obj.FREE;
    WINNERS = obj.WINNERS;
    WINNER = obj.WINNER;
    DISTRIBUTION = obj.DISTRIBUTION;
    MULTI_ENTRY_CONTEST = obj.MULTI_ENTRY_CONTEST;
    MULTI_ENTRY_DISCRIPTION = obj.MULTI_ENTRY_DISCRIPTION;
    GUARANTEED_DESCRIPTION = obj.GUARANTEED_DESCRIPTION;
    GUARANTEED_CONTEST = obj.GUARANTEED_CONTEST;
    GUARANTEED = obj.GUARANTEED;
    SINGLE = obj.SINGLE;
    CONFIRM_DESCRIPTION = obj.CONFIRM_DESCRIPTION;
    CONFIRM_CONTEST = obj.CONFIRM_CONTEST;
    PRIVATE_CONTEST = obj.PRIVATE_CONTEST;
    FRIENDS_PLAY_BONUS = obj.FRIENDS_PLAY_BONUS;
    VS = obj.VS;
    PRIZE_MSG1 = obj.PRIZE_MSG1;
    PRIZE_MSG2 = obj.PRIZE_MSG2;
    PRIZE_MSG3 = obj.PRIZE_MSG3;
    PRIZE_MSG4 = obj.PRIZE_MSG4;
    GUARANTEED_PRIZE_MSG1 = obj.GUARANTEED_PRIZE_MSG1;
    GUARANTEED_PRIZE_MSG2 = obj.GUARANTEED_PRIZE_MSG2;
    GUARANTEED_PRIZE_MSG3 = obj.GUARANTEED_PRIZE_MSG3;
    GUARANTEED_PRIZE_MSG4 = obj.GUARANTEED_PRIZE_MSG4;
    TDS_TEXT = obj.TDS_TEXT;
    IST = obj.IST;
    PULL_DOWN_TO_REFRESH = obj.PULL_DOWN_TO_REFRESH;
    RELEASE_TO_REFRESH = obj.RELEASE_TO_REFRESH;
    THIS_FEATURE_IS_COMING_SOON = obj.THIS_FEATURE_IS_COMING_SOON;
    MIN_CAMEL_CASE = obj.MIN_CAMEL_CASE;
    DONE = obj.DONE;
    ALL = obj.ALL;
    NO_TEAM_MSG = obj.NO_TEAM_MSG;
    THIS_CONTEST = obj.THIS_CONTEST;
    MY_TEAMS = obj.MY_TEAMS;
    JOIN_NOW = obj.JOIN_NOW;
    CONTEST_JOIN_NOW=obj.CONTEST_JOIN_NOW;
    NO_CONTEST_FOR_FILTER = obj.NO_CONTEST_FOR_FILTER;
    NO_DATA_FOR_FILTER = obj.NO_DATA_FOR_FILTER;
    NO_RESULT_FOUND_FILTER_1 = obj.NO_RESULT_FOUND_FILTER_1;
    NO_CONTEST_FOR_FILTER_1 = obj.NO_CONTEST_FOR_FILTER_1;
    NO_CONTEST_FOR_FILTER_2 = obj.NO_CONTEST_FOR_FILTER_2;
    FILTERS = obj.FILTERS;
    MAX_ENTRIES = obj.MAX_ENTRIES;
    Earn = obj.Earn;
    Coin = obj.Coin;
    on_your_friends_signup = obj.on_your_friends_signup;
    on_your_first_cash_contest = obj.on_your_first_cash_contest;
    MY_TEAM = obj.MY_TEAM;
    View_All_Players = obj.View_All_Players;
    COLLECTION_CONTEST_DISCRIPTION = obj.COLLECTION_CONTEST_DISCRIPTION;
    MATCHES_SM = obj.MATCHES_SM;
    COLLECTION = obj.COLLECTION;
    FIXTURE_TAB=obj.FIXTURE_TAB;
    MAX_TEAM_FOR_MULTI_ENTRY = obj.MAX_TEAM_FOR_MULTI_ENTRY;
    SINGLE_TEAM_DESCRIPTION = obj.SINGLE_TEAM_DESCRIPTION;
    TRENDING_DESCRIPTION = obj.TRENDING_DESCRIPTION;
    TRENDING = obj.TRENDING;
    MAX_MULTI_ENTRY_TEAM = obj.MAX_MULTI_ENTRY_TEAM;
    SALARY_LEFT = obj.SALARY_LEFT;
    DELAYED = obj.DELAYED;

    MULTIGAME_TITLE=obj.MULTIGAME_TITLE;
    MULTIGAME_DETAIL=obj.MULTIGAME_DETAIL;
    SELECT_MULTIGAME_CONTEST_TITLE=obj.SELECT_MULTIGAME_CONTEST_TITLE;
    SELECT_MULTIGAME_CONTEST_DETAIL=obj.SELECT_MULTIGAME_CONTEST_DETAIL;
    CHOOSE_PLAYER=obj.CHOOSE_PLAYER;
    FIXTURE=obj.FIXTURE;
    VIEW_PROOF=obj.VIEW_PROOF;
    PROOF=obj.PROOF;
    NO_QUE_FOR_CATEGORY=obj.NO_QUE_FOR_CATEGORY;
    SWITCH_TO_OTHER_CAT=obj.SWITCH_TO_OTHER_CAT;
    EXCITING_QUE_ON_WAY=obj.EXCITING_QUE_ON_WAY;
    PICKEM_HTP_LABEL1=obj.PICKEM_HTP_LABEL1;
    PICKEM_HTP_MSG1=obj.PICKEM_HTP_MSG1;
    PICKEM_HTP_LABEL2=obj.PICKEM_HTP_LABEL2;
    PICKEM_HTP_MSG2=obj.PICKEM_HTP_MSG2;
    PICKEM_HTP_LABEL3=obj.PICKEM_HTP_LABEL3;
    PICKEM_HTP_MSG3=obj.PICKEM_HTP_MSG3;
    PARTICIPANTS=obj.PARTICIPANTS;
    PICKEM_SHARE_MSG=obj.PICKEM_SHARE_MSG;
    MAXIMUM=obj.Maximum;
    CURRENT=obj.Current;
    THIS_WILL_BE_UPDATED=obj.THIS_WILL_BE_UPDATED;
    PEOPLE_JOINED_THIS_CONTEST=obj.PEOPLE_JOINED_THIS_CONTEST;
    Use=obj.Use;
    BONUS_CASH_CONTEST_LISTING=obj.BONUS_CASH_CONTEST_LISTING;
    PLAYED_LAST_MATCH=obj.PLAYED_LAST_MATCH;



}

function translateRosterData() {
    let obj = transtale('rosterfield');
    RESET_ACTION = obj.RESET_ACTION;
    RESET_TEAM = obj.RESET_TEAM;
    CREDITS_LEFT = obj.CREDITS_LEFT;
    SELECTED = obj.SELECTED;
    SELECT = obj.SELECT;
    FILTER_BY_TEAM = obj.FILTER_BY_TEAM;
    FIND_A_PLAYER = obj.FIND_A_PLAYER;
    PLAYER = obj.PLAYER;
    POINTS = obj.POINTS;
    GETS = obj.GETS;
    SALARY = obj.SALARY;
    SALARY_ABR = obj.SALARY_ABR;
    NEXT = obj.NEXT;
    FANTASY_PTS = obj.FANTASY_PTS;
    VALUE = obj.VALUE;
    FORM = obj.FORM;
    GAME_LOG = obj.GAME_LOG;
    LAST_5_MATCHES = obj.LAST_5_MATCHES;
    DATE = obj.DATE;
    FORMAT = obj.FORMAT;
    RUNS = obj.RUNS;
    WKT = obj.WKT;
    E_R = obj.E_R;
    S_R = obj.S_R;
    PTS = obj.PTS;
    ROUND1 = obj.ROUND1;
    ROUND2 = obj.ROUND2;
    ROUND3 = obj.ROUND3;
    RESET = obj.RESET;
    RANK = obj.RANK;
    ENTER_TAM_NAME = obj.ENTER_TAM_NAME;
    CHOOSE_CAPTAIN_VICE_CAPTAIN = obj.CHOOSE_CAPTAIN_VICE_CAPTAIN;
    CHOOSE_CAPTAIN = obj.CHOOSE_CAPTAIN;
    PICK_CAPTAIN = obj.PICK_CAPTAIN;
    CAPTAIN_GETS = obj.CAPTAIN_GETS;
    C = obj.C;
    VC = obj.VC;
    VICE_CAPTAIN_GETS = obj.VICE_CAPTAIN_GETS;
    SORT_BY = obj.SORT_BY;
    PLAYERS = obj.PLAYERS;
    SUBMIT_LINEUP = obj.SUBMIT_LINEUP;
    CONFIRMATION = obj.CONFIRMATION;
    JOINING_CONTEST_WITH = obj.JOINING_CONTEST_WITH;
    ENTRY_FEE = obj.ENTRY_FEE;
    Entry_fee = obj.Entry_fee;
    PAYABLE_BALANCE = obj.PAYABLE_BALANCE;
    JOINING_TEAM_WITH = obj.JOINING_TEAM_WITH;
    SELECT_TEAM = obj.SELECT_TEAM;
    CREATE_NEW_TEAM = obj.CREATE_NEW_TEAM;
    HAVE_A_PROMO_CODE = obj.HAVE_A_PROMO_CODE;
    PROMO_CODE = obj.PROMO_CODE;
    ENTER_PROMO_CODE = obj.ENTER_PROMO_CODE;
    APPLY = obj.APPLY;
    JOIN_CONTEST = obj.JOIN_CONTEST;
    WITH_TEAMS = obj.WITH_TEAMS;
    ADD_FUND_JOIN_CONTEST = obj.ADD_FUND_JOIN_CONTEST;
    PROMO_TEXT1 = obj.PROMO_TEXT1;
    PROMO_TEXT2 = obj.PROMO_TEXT2;
    PROMO_TEXT3 = obj.PROMO_TEXT3;
    PAYABLE_TOOLTIP1 = obj.PAYABLE_TOOLTIP1;
    PAYABLE_TOOLTIP2 = obj.PAYABLE_TOOLTIP2;
    JOIN_SUCCESS_TITLE = obj.JOIN_SUCCESS_TITLE
    JOIN_SUCCESS_MESSAGE = obj.JOIN_SUCCESS_MESSAGE
    JOIN_MORE = obj.JOIN_MORE;
    JOIN_MORE_POPUP = obj.JOIN_MORE_POPUP;
    CONTESTS = obj.CONTESTS;
    CONTESTS_POPUP = obj.CONTESTS_POPUP;
    SEE_MY_CONTESTS = obj.SEE_MY_CONTESTS;
    DISMISS = obj.DISMISS;
    OF = obj.OF;
    CONTEST_JOINED = obj.CONTEST_JOINED;
    TEAMS2 = obj.TEAMS2;
    TAB_TO_SEE_FIELD_VIEW = obj.TAB_TO_SEE_FIELD_VIEW;
    MAX_PLAYER_TEAMWISE = obj.MAX_PLAYER_TEAMWISE;
    MAX_PLAYER_TEAMWISE1 = obj.MAX_PLAYER_TEAMWISE1;
    VERSES = obj.VERSES;
    MAX = obj.MAX;
    CHOOSE_VICE_CAPTAIN = obj.CHOOSE_VICE_CAPTAIN;
    SELECT_MATCH_TEXT = obj.SELECT_MATCH_TEXT;
    CREATE_TEAM = obj.CREATE_TEAM;
    PLAYERS_FROM_A_TEAM = obj.PLAYERS_FROM_A_TEAM;
    PICK = obj.PICK;
    TEAM_PREVIEW = obj.TEAM_PREVIEW;
    GENERAT_YOUR_TEAM = obj.GENERAT_YOUR_TEAM;
    START_OVER = obj.START_OVER;
    SALARY_REMAINING = obj.SALARY_REMAINING;
    REMOVE_PLAYER = obj.REMOVE_PLAYER;
    LOCK_PLAYER = obj.LOCK_PLAYER;
    WANT_MORE_THEN_ONE_TEAM = obj.WANT_MORE_THEN_ONE_TEAM;
    CLICK_HERE = obj.CLICK_HERE;
    REFRESH_TEAM = obj.REFRESH_TEAM;
    SAVE_THIS_LINEUP = obj.SAVE_THIS_LINEUP;
    GENERAT_LINEUP = obj.GENERAT_LINEUP;
    POWERED_BY = obj.POWERED_BY;
    EXCLUDED = obj.EXCLUDED;
    AI_HELP_MESSAGE =obj.AI_HELP_MESSAGE;
    LOCK_PLAYER_TITLE= obj.LOCK_PLAYER_TITLE;
    LOCK_PLAYER_TITLE_DESCRIPTION= obj.LOCK_PLAYER_TITLE_DESCRIPTION;
    REMOVE_PLAYER_TITLE= obj.REMOVE_PLAYER_TITLE;
    REMOVE_PLAYER_TITLE_DESCRIPTION= obj.REMOVE_PLAYER_TITLE_DESCRIPTION;
    GENRATE_PLAYER_TITLE= obj.GENRATE_PLAYER_TITLE;
    GENRATE_PLAYER_TITLE_DESCRIPTION= obj.GENRATE_PLAYER_TITLE_DESCRIPTION;
    DONT_SHOW_THIS_AGAIN= obj.DONT_SHOW_THIS_AGAIN;
    MANAGE_PLAYER_TITLE= obj.MANAGE_PLAYER_TITLE;
    MANAGE_PLAYER_TITLE_DESCRIPTION= obj.MANAGE_PLAYER_TITLE_DESCRIPTION;
    REFRSH_TEAM_TITLE= obj.REFRSH_TEAM_TITLE;
    REFRSH_TEAM_TITLE_DESCRIPTION= obj.REFRSH_TEAM_TITLE_DESCRIPTION;
    SAVE_TEAM_TITLE= obj.SAVE_TEAM_TITLE;
    SAVE_TEAM_TITLE_DESCRIPTION= obj.SAVE_TEAM_TITLE_DESCRIPTION;
    PL_1_TITLE =obj.PL_1_TITLE;
    PL_1_DESCRIPTION =obj.PL_1_DESCRIPTION;
    PL_2_TITLE =obj.PL_2_TITLE;
    PL_2_DESCRIPTION =obj.PL_2_DESCRIPTION;
    PL_3_TITLE =obj.PL_3_TITLE;
    PL_3_DESCRIPTION =obj.PL_3_DESCRIPTION;
    PL_4_TITLE =obj.PL_4_TITLE;
    PL_4_DESCRIPTION =obj.PL_4_DESCRIPTION;
    PL_5_TITLE =obj.PL_5_TITLE;
    PL_5_DESCRIPTION =obj.PL_5_DESCRIPTION;
}

function translatePlayerCardData() {
    let obj = transtale('playercard');
    EXPECTED_SCORE = obj.EXPECTED_SCORE;
}

function translateMyContestData() {
    let obj = transtale('mycontest');
    NO_UPCOMING_CONTEST1 = obj.NO_UPCOMING_CONTEST1;
    NO_UPCOMING_CONTEST2 = obj.NO_UPCOMING_CONTEST2;
    NO_LIVE_CONTEST1 = obj.NO_LIVE_CONTEST1;
    NO_LIVE_CONTEST2 = obj.NO_LIVE_CONTEST2;
    NO_COMPLETED_CONTEST1 = obj.NO_COMPLETED_CONTEST1;
    NO_COMPLETED_CONTEST2 = obj.NO_COMPLETED_CONTEST2;
    GO_TO_LOBBY = obj.GO_TO_LOBBY;
    LIVE = obj.LIVE;
    UPCOMING = obj.UPCOMING;
    ACTIVE = obj.ACTIVE
    COMPLETED = obj.COMPLETED;
    IN_PROGRESS = obj.IN_PROGRESS;
    STANDINGS = obj.STANDINGS;
    SWITCH_TEAM = obj.SWITCH_TEAM;
    EDIT_TEAM = obj.EDIT_TEAM;
    VIEW_TEAM = obj.VIEW_TEAM;
    WON = obj.WON;
    YOUR_CURRENT_TEAM = obj.YOUR_CURRENT_TEAM;
    SWITCH_WITH = obj.SWITCH_WITH;
    You = obj.You;
    Team = obj.Team;
    Pts = obj.Pts;
    Ranks = obj.Ranks;
    YOUR_PICKS_HGHT = obj.YOUR_PICKS_HGHT;
    NO_UPCOMING_PICKEM1 = obj.NO_UPCOMING_PICKEM1;
    NO_UPCOMING_PICKEM2 = obj.NO_UPCOMING_PICKEM2;
    NO_LIVE_PICKEM1 = obj.NO_LIVE_PICKEM1;
    NO_LIVE_PICKEM2 = obj.NO_LIVE_PICKEM2;
    NO_COMPLETED_PICKEM1 = obj.NO_COMPLETED_PICKEM1;
    NO_COMPLETED_PICKEM2 = obj.NO_COMPLETED_PICKEM2;
}

function translateCreateContestData() {
    let obj = transtale('createcontest');
    SELECT_LEAGUE = obj.SELECT_LEAGUE;
    MULTI_LINEUP = obj.MULTI_LINEUP;
    MATCHES = obj.MATCHES
    Min_team_size = obj.Min_team_size
    Max_team_size = obj.Max_team_size
    Number_of_winners = obj.Number_of_winners
    CONTEST_NAME = obj.CONTEST_NAME
    Contest_Name = obj.Contest_Name
    PRIZE_POOL = obj.PRIZE_POOL
    PRIZES = obj.PRIZES
    Prize_Distribution = obj.Prize_Distribution
    WINNING = obj.WINNING
    TOTAL = obj.TOTAL
    CREATE_SHARE = obj.CREATE_SHARE
    SELECT_LEAGUE_ERROR = obj.SELECT_LEAGUE_ERROR
    SELECT_MATCH = obj.SELECT_MATCH
    SELECT_MINIMUM_TEAM = obj.SELECT_MINIMUM_TEAM
    SELECT_MAX_TEAMS = obj.SELECT_MAX_TEAMS
    MIN_TEAM_CONDITION = obj.MIN_TEAM_CONDITION
    MAX_TEAM_CONDITION = obj.MAX_TEAM_CONDITION
    SELECT_ENTRY_FEE = obj.SELECT_ENTRY_FEE
    SELECT_WINNERS_COUNT = obj.SELECT_WINNERS_COUNT
    SELECT_CONTEST_NAME = obj.SELECT_CONTEST_NAME
    SELECT_CONTEST_NAME_MIN_CONDITION = obj.SELECT_CONTEST_NAME_MIN_CONDITION
    WINNERS_CONDITION = obj.WINNERS_CONDITION
    PRIZE_STRUCTURE_ERROR = obj.PRIZE_STRUCTURE_ERROR
    WINNING_PER_EQ100 = obj.WINNING_PER_EQ100
    WINNING_PER_EQLESS100 = obj.WINNING_PER_EQLESS100
    WINNING_AMT_CONDITION = obj.WINNING_AMT_CONDITION
    PLEASE_ENTER_AMOUNT = obj.PLEASE_ENTER_AMOUNT
    ENTERED_AMOUNT_MUST_BE_5 = obj.ENTERED_AMOUNT_MUST_BE_5
    PLEASE_ENTER_ATLEAST_4_DIGIT = obj.PLEASE_ENTER_ATLEAST_4_DIGIT
    PLEASE_ENTER_PROMO_CODE = obj.PLEASE_ENTER_PROMO_CODE
}

function translateWalletData() {
    let obj = transtale('mywallet');
    ADD_FUNDS = obj.ADD_FUNDS;
    WITHDRAW = obj.WITHDRAW;
    EARN_COINS = obj.EARN_COINS;
    EARN_COINS_LOWCASE = obj.EARN_COINS_LOWCASE;
    RECENT_TRANSACTION = obj.RECENT_TRANSACTION;
    VIEW_ALL_TRANSACTION = obj.VIEW_ALL_TRANSACTION;
    TRANSACTION_STATUS_PENDING = obj.TRANSACTION_STATUS_PENDING;
    TRANSACTION_STATUS_SUCCESS = obj.TRANSACTION_STATUS_SUCCESS;
    TRANSACTION_STATUS_FAILED = obj.TRANSACTION_STATUS_FAILED;
    CREDITS = obj.CREDITS;
    CREDIT = obj.CREDIT;
    credit = obj.credit;
    DEBIT = obj.DEBIT;
    REAL_WINNING_BONUS_AMT = obj.REAL_WINNING_BONUS_AMT;
    TODAY = obj.TODAY;
    DEPOSIT_BALANCE = obj.DEPOSIT_BALANCE;
    PAYUMONEY = obj.PAYUMONEY;
    PAYTM = obj.PAYTM;
    GO_CASH = obj.GO_CASH;
    MPESA = obj.MPESA;
    ADD_AMOUNT_TO_WALLET = obj.ADD_AMOUNT_TO_WALLET1 + (Utilities.getMasterData() ? Utilities.getMasterData().currency_code : '') + obj.ADD_AMOUNT_TO_WALLET2;
    MIN_WITHDRAW_LIMIT = `${obj.MIN_WITHDRAW_LIMIT1} ` + (Utilities.getMasterData() ? Utilities.getMasterData().currency_code : '');
    MAX_WITHDRAW_LIMIT = obj.MAX_WITHDRAW_LIMIT;
    BANK_DETAILS = obj.BANK_DETAILS;
    PAN_TO_ID = obj.PAN_TO_ID;
    AMOUNT = obj.AMOUNT1 + (Utilities.getMasterData() ? Utilities.getMasterData().currency_code : '') + obj.AMOUNT2;
    PANCARD_PENDING_STATUS = obj.PANCARD_PENDING_STATUS;
    PANCARD_REJECTED_STATUS = obj.PANCARD_REJECTED_STATUS;
    PANCARD_REJECTED_STATUS_MSG = obj.PANCARD_REJECTED_STATUS_MSG;
    FULL_NAME_AS_BANK = obj.FULL_NAME_AS_BANK;
    BANK_NAME = obj.BANK_NAME;
    ACCOUNT_NUMBER = obj.ACCOUNT_NUMBER;
    IFSC_CODE = obj.IFSC_CODE;
    PANCARD_NUMBER = obj.PANCARD_NUMBER;
    PANCARD = obj.PANCARD;
    MAX_SIZE_UPLOAD = obj.MAX_SIZE_UPLOAD;
    UPLOAD_FORMATS = obj.UPLOAD_FORMATS;
    UPDATE_BANK_DETAIL_FROM_PROFILE = obj.UPDATE_BANK_DETAIL_FROM_PROFILE;
    YOU_CAN_UPDATE_YOUR = obj.YOU_CAN_UPDATE_YOUR;
    BANK_DETAIL_FROM_PROFILE = obj.BANK_DETAIL_FROM_PROFILE;
    UPDATE = obj.UPDATE;
    WITHDRAW_ALERT = obj.WITHDRAW_ALERT;
    PROFILE = obj.PROFILE;
    PROFILE_SMALL = obj.PROFILE_SMALL;
    NOTE = obj.NOTE;
    WATCH_VIDEO_N_GET = obj.WATCH_VIDEO_N_GET;
    ADDED_TO_ACCOUNT = obj.ADDED_TO_ACCOUNT;
    THANKYOU = obj.THANKYOU;
    WITHDRAW_PROCESS1 = obj.WITHDRAW_PROCESS1;
    WITHDRAW_PROCESS2 = obj.WITHDRAW_PROCESS2;
    OK = obj.OK;
    CANCEL = obj.CANCEL;
    ALERT = obj.ALERT;
    BANK_TRANSFER = obj.BANK_TRANSFER;
    Your_payment_successful = obj.Your_payment_successful;
    Your_payment_failed = obj.Your_payment_failed;
    Your_payment_pending = obj.Your_payment_pending;
    Coins_in_your_wallet = obj.Coins_in_your_wallet;
    coins = obj.coins;
    Price = obj.Price;
    Place_order = obj.Place_order;
    Min_withdraw_amount_is = obj.Min_withdraw_amount_is;
    YOUR_WINNINGS = obj.YOUR_WINNINGS;
    MONEY_YOU_WON = obj.MONEY_YOU_WON;
    VERIFY_YOUR_ACCOUNT_TO_ACTIVATE_WITHDRAW_MONEY_SERVICE = obj.VERIFY_YOUR_ACCOUNT_TO_ACTIVATE_WITHDRAW_MONEY_SERVICE;
    DEPOSIT = obj.DEPOSIT;
    VERIFY_YOUR_ACCOUNT = obj.VERIFY_YOUR_ACCOUNT;
    VERIFY_GET = obj.VERIFY_GET;
    VERIFICATION_PENDING = obj.VERIFICATION_PENDING;
    TRANS_ID = obj.TRANS_ID;
    STATUS = obj.STATUS;
    PANCARD_VERIFICATION = obj.PANCARD_VERIFICATION;
    PHONE_VERIFICATION = obj.PHONE_VERIFICATION;
    FIRST_CONTEST = obj.FIRST_CONTEST;
    FIFTH_CONTEST = obj.FIFTH_CONTEST;
    THENTH_CONTEST = obj.THENTH_CONTEST;
    NAME_ON_PANCARD = obj.NAME_ON_PANCARD;
    VERIFY_PANCARD = obj.VERIFY_PANCARD;
    IMPORTANT_PAN_1 = obj.IMPORTANT_PAN_1;
    IMPORTANT_PAN_2 = obj.IMPORTANT_PAN_2;
    IMPORTANT_PAN_3 = obj.IMPORTANT_PAN_3;
    BankVerification = obj.BankVerification;
    ADD_CASH = obj.ADD_CASH;
    TOTAL_WINNINGS = obj.TOTAL_WINNINGS;
    YOU_CAN_WITHDRAW_ONLY_FROM_WINNING = obj.YOU_CAN_WITHDRAW_ONLY_FROM_WINNING;
    YOUR_WITHDRAWAL_AMOUNT_WILL_BE_CREDITED_TO_THIS_ACCOUNT = obj.YOUR_WITHDRAWAL_AMOUNT_WILL_BE_CREDITED_TO_THIS_ACCOUNT;
    YOUR_WITHDRAWAL_REQUEST_IS_PENDING = obj.YOUR_WITHDRAWAL_REQUEST_IS_PENDING;
    VERIFY_BANK_DETAILS = obj.VERIFY_BANK_DETAILS;
    SELECT_PAYMENT_METHOD = obj.SELECT_PAYMENT_METHOD;
    TO_PAY = obj.TO_PAY;
    CREDIT_DEBIT_CARD = obj.CREDIT_DEBIT_CARD;
    PAYTM_WALLET = obj.PAYTM_WALLET;
    OTHER_WALLET = obj.OTHER_WALLET;
    UPI = obj.UPI;
    NET_BANKING = obj.NET_BANKING;
    TRANSACTION_STATUS_REJECTED = obj.TRANSACTION_STATUS_REJECTED;
    DELETE = obj.DELETE;
    VERIFY_YOUR_ACCOUNT_FIRST = obj.VERIFY_YOUR_ACCOUNT_FIRST;
    PAY_WITH_DEBIT_CARD_CREDIT_CARD_UPI_MORE = obj.PAY_WITH_DEBIT_CARD_CREDIT_CARD_UPI_MORE;
    WHERE__HOW_MUCH_SPENT_KNOW_ALL = obj.WHERE__HOW_MUCH_SPENT_KNOW_ALL;
    PENDING_AMOUNT = obj.PENDING_AMOUNT;
    RAISE_ANOTHER_WITHDRAWAL_REQUEST = obj.RAISE_ANOTHER_WITHDRAWAL_REQUEST;
    PANCARD_HELP_TEXT = obj.PANCARD_HELP_TEXT;
    INVALID_PROMOCODE = obj.INVALID_PROMOCODE;
    PLEASE_ENTER_VALID_PAN_CARD_NUMBER = obj.PLEASE_ENTER_VALID_PAN_CARD_NUMBER;
    WITHDRAW_YOUR_WINNINGS_IN_YOUR_ACCOUNT = obj.WITHDRAW_YOUR_WINNINGS_IN_YOUR_ACCOUNT;
    YOUR_ACCOUNT_IS_VERIFIED = obj.YOUR_ACCOUNT_IS_VERIFIED;
    YOUR_ACCOUNT_IS_VERIFIED1 = obj.YOUR_ACCOUNT_IS_VERIFIED1;
    VIEW = obj.VIEW;
    INSUFFICIENT_BALANCE = obj.INSUFFICIENT_BALANCE;
    WITHDRAW_MONEY = obj.WITHDRAW_MONEY;
    TRANSACTION_HISTORY = obj.TRANSACTION_HISTORY;
    TRANSACTIONS = obj.TRANSACTIONS;
    NEED_HELP = obj.NEED_HELP;
    HRS24_SUPPORT = obj.HRS24_SUPPORT;
    ADDITIONAL_INFO = obj.ADDITIONAL_INFO;
    ADDITIONAL_PAN_DESC = obj.ADDITIONAL_PAN_DESC;
    ADDITIONAL_BANK_DESC = obj.ADDITIONAL_BANK_DESC;
    IMPORTANT_BANK_1 = obj.IMPORTANT_BANK_1;
    IMPORTANT_BANK_2 = obj.IMPORTANT_BANK_2;
    IMPORTANT_BANK_3 = obj.IMPORTANT_BANK_3;
    IMPORTANT_BANK_4 = obj.IMPORTANT_BANK_4;
    SUFFICIENT_WINNING = obj.SUFFICIENT_WINNING;
    VALID_UPI_MESSAGE=obj.VALID_UPI_MESSAGE;
}

function translateInviteData() {
    let obj = transtale('invite');
    INVITE_YOUR_FRIENDS_VIA = obj.INVITE_YOUR_FRIENDS_VIA;
    INVITE_LINK = obj.INVITE_LINK;
    INVITE_FB = obj.INVITE_FB;
    INVITE_WHATSAPP = obj.INVITE_WHATSAPP;
    INVITE_EMAIL = obj.INVITE_EMAIL;
    INVITE_FRIENDS = obj.INVITE_FRIENDS;
    INVITE_FRIENDS_EARN = obj.INVITE_FRIENDS_EARN;
    JOINED = obj.JOINED;
    CASH = obj.CASH;
    MY_REFERRALS = obj.MY_REFERRALS;
    BONUS_EARNED = obj.BONUS_EARNED;
    CASH_EARNED = obj.CASH_EARNED;
    COINS_EARNED = obj.COINS_EARNED;
    SHARE = obj.SHARE;
    REFERRAL = obj.REFERRAL;
    EMAIL_VERIFIED = obj.EMAIL_VERIFIED;
    FIRST_DEPOSIT = obj.FIRST_DEPOSIT;
    PAN_VERIFIED = obj.PAN_VERIFIED;
    SHARE_REFERRAL_CODE = obj.SHARE_REFERRAL_CODE;
    COPY = obj.COPY;
    TELL_YOUR_FRIENDS_SIGNUP = obj.TELL_YOUR_FRIENDS_SIGNUP;
    TELL_YOUR_FRIENDS_JOIN_CONTEST = obj.TELL_YOUR_FRIENDS_JOIN_CONTEST;
    TOP_REFERRERS = obj.TOP_REFERRERS;
    EARNED = obj.EARNED;
    TOTAL_EARNED = obj.TOTAL_EARNED;
    REAL_CASH = obj.REAL_CASH;
    BONUS_CASH = obj.BONUS_CASH;
    COINS = obj.COINS;
    NOT_REFER = obj.NOT_REFER;
    YOUR_FRIENDS_YET = obj.YOUR_FRIENDS_YET;
    SHARE_NOW = obj.SHARE_NOW;
    MERCHANDISE = obj.MERCHANDISE;
    FREE_CONTEST = obj.FREE_CONTEST;
    SHARE_CONTEST_CODE = obj.SHARE_CONTEST_CODE;
    Hi = obj.Hi;
    contest_and_win_real_cash = obj.contest_and_win_real_cash;
    League_Url = obj.League_Url;
    Your_friend_has_shared = obj.Your_friend_has_shared;
    Your_friend_has_shared_pick = obj.Your_friend_has_shared_pick;
    WinFantasy = obj.WinFantasy;
    Your_friend_has_referred_you = obj.Your_friend_has_referred_you;
    Your_Friend = obj.Your_Friend;
    has_referred_you_on = obj.has_referred_you_on;
    please_join_and_earn_prizes = obj.please_join_and_earn_prizes;
    please_join_and_earn_prizes_text = obj.please_join_and_earn_prizes_text;
    or = obj.or;
    Join_through_the_following = obj.Join_through_the_following;
    Join_through_the_following_text = obj.Join_through_the_following_text;
    YOUR_FRIEND_CONTEST = obj.YOUR_FRIEND_CONTEST;
    has_referred_you_on_contest = obj.has_referred_you_on_contest;
    please_join_and_earn_prizes_text_contest = obj.please_join_and_earn_prizes_text_contest;
    OR_CONTEST = obj.OR_CONTEST;
    Join_through_the_following_text_contest = obj.Join_through_the_following_text_contest;
    and_contest_code_contest = obj.and_contest_code_contest;
    CHEERS_CONTEST = obj.Cheers_CONTEST;
    TEAMS_CONTEST = obj.TEAMS_CONTEST;
    WHILE_SIGNING_UP = obj.WHILE_SIGNING_UP;
    and_contest_code = obj.and_contest_code;
    MEDIUM_ADD=obj.MEDIUM_ADD;
    Cheers = obj.Cheers;
    Teams = obj.Teams;
    Link_has_been_copied = obj.Link_has_been_copied;
    MSZ_COPY_CODE = obj.MSZ_COPY_CODE;
    Promocode_has_been_applied = obj.Promocode_has_been_applied;
    ON_ENTERING_YOUR_FRIENDS_REFERRAL_CODE = obj.ON_ENTERING_YOUR_FRIENDS_REFERRAL_CODE;
    REAL_CASH_FOR_EVERY_FRIEND_YOU_REFER = obj.REAL_CASH_FOR_EVERY_FRIEND_YOU_REFER;
    BONUS_FOR_EVERY_FRIEND_YOU_REFER = obj.BONUS_FOR_EVERY_FRIEND_YOU_REFER;
    COINS_FOR_EVERY_FRIEND_YOU_REFER = obj.COINS_FOR_EVERY_FRIEND_YOU_REFER;
    REFER_YOU_AND_EARNED = obj.REFER_YOU_AND_EARNED;
    EARN_REAL_CASH_WHEN_YOUR_FRIEND_SIGNS_UP = obj.EARN_REAL_CASH_WHEN_YOUR_FRIEND_SIGNS_UP;
    SHARE_YOUR_CODE = obj.SHARE_YOUR_CODE;
    BRING_YOUR_FRIENDS_AND = obj.BRING_YOUR_FRIENDS_AND;
    PLAY_FOR_FREE = obj.PLAY_FOR_FREE;
    YOUR_FRIEND = obj.YOUR_FRIEND;
    THERE_WILL_BE_NO_REFERRAL_SHARING_NW_CONTEST = obj.THERE_WILL_BE_NO_REFERRAL_SHARING_NW_CONTEST;
   }

function translateProfileData() {
    let obj = transtale('profile');
    USER_NAME = obj.USER_NAME;
    BASIC_INFO = obj.BASIC_INFO;
    EMAIL = obj.EMAIL;
    DOB = obj.DOB;
    GENDER = obj.GENDER;
    MOBILE_NUMBER = obj.MOBILE_NUMBER;
    ADDRESS = obj.ADDRESS;
    EDIT_PROFILE = obj.EDIT_PROFILE;
    FIRST_NAME = obj.FIRST_NAME;
    LAST_NAME = obj.LAST_NAME;
    VERIFY = obj.VERIFY;
    VERIFIED = obj.VERIFIED;
    SELECT_DOB = obj.SELECT_DOB;
    SELECT_GENDER = obj.SELECT_GENDER;
    MALE = obj.MALE;
    FEMALE = obj.FEMALE;
    SETREET_ADDRESS = obj.SETREET_ADDRESS;
    COUNTRY = obj.COUNTRY;
    STATE = obj.STATE;
    CITY = obj.CITY;
    PIN_CODE = obj.PIN_CODE;
    POSTAL_CODE = obj.POSTAL_CODE;
    MICR_CODE = obj.MICR_CODE;
    UPLOAD_PAN_CARD = obj.UPLOAD_PAN_CARD;
    UPLOAD = obj.UPLOAD;
    UPLOAD_BANK_DOC = obj.UPLOAD_BANK_DOC;
    UPLOAD_BANK_DOC_DESC = obj.UPLOAD_BANK_DOC_DESC;
    BANK_PROFILE_VERIFIED = obj.BANK_PROFILE_VERIFIED;
    is_invalid = obj.is_invalid;
    Please_select_DOB = obj.Please_select_DOB;
    Please_select_Gender = obj.Please_select_Gender;
    Please_enter_mobile_number = obj.Please_enter_mobile_number;
    Please_upload_ID_card = obj.Please_upload_ID_card;
    Please_upload_Bank_document = obj.Please_upload_Bank_document;
    VERIFY_YOUR_DETAILS_TO_ENJOY_SEAMLESS_WITHDRAWLS = obj.VERIFY_YOUR_DETAILS_TO_ENJOY_SEAMLESS_WITHDRAWLS;
    PLAYING_EXPERIENCE = obj.PLAYING_EXPERIENCE;
    PRIMARY_INFO = obj.PRIMARY_INFO;
    MOBILE = obj.MOBILE;
    BANK = obj.BANK;
    PAN = obj.PAN;
    EMAIL_VERIFICATION = obj.EMAIL_VERIFICATION;
    EMAIL_VERIFICATION_TEXT = obj.EMAIL_VERIFICATION_TEXT;
    YES = obj.YES;
    NO = obj.NO;
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_BANK_DETAILS = obj.ARE_YOU_SURE_YOU_WANT_TO_DELETE_BANK_DETAILS;
    EDIT_USERNAME = obj.EDIT_USERNAME;
    CHECK_AVAILABILITY = obj.CHECK_AVAILABILITY;
    EDIT_MOBILE_NUMBER = obj.EDIT_MOBILE_NUMBER;
    EDIT_EMAIL_ADDRESS = obj.EDIT_EMAIL_ADDRESS;
    VERIFY_EMAIL_ADDRESS = obj.VERIFY_EMAIL_ADDRESS;
    ADD_AMOUNT = obj.ADD_AMOUNT;
    ENTER_AMOUNT = obj.ENTER_AMOUNT;
    PICK_BEST_DEAL = obj.PICK_BEST_DEAL;
    YOU_WILL_ADDITIONALLY_GET = obj.YOU_WILL_ADDITIONALLY_GET;
    EDIT_BASIC_INFO = obj.EDIT_BASIC_INFO;
    USERNAME_HAS_BEEN_UPDATED_SUCCUSSFULLY = obj.USERNAME_HAS_BEEN_UPDATED_SUCCUSSFULLY;
    YOUR_MOBILE_NUMBER_HAS_BEEN_UPDATED_SUCCUSSFULLY = obj.YOUR_MOBILE_NUMBER_HAS_BEEN_UPDATED_SUCCUSSFULLY;
    YOUR_EMAIL_ADDRESS_HAS_BEEN_UPDATED_SUCCUSSFULLY = obj.YOUR_EMAIL_ADDRESS_HAS_BEEN_UPDATED_SUCCUSSFULLY;
    VERIFY_AND_UPDATE = obj.VERIFY_AND_UPDATE;
    WRONG_OTP = obj.WRONG_OTP;
    YOUR_FULL_NAME = obj.YOUR_FULL_NAME;
    CANT_EDIT_AFTER_PAN_APPROVAL = obj.CANT_EDIT_AFTER_PAN_APPROVAL;
    ARE_YOU_SURE_YOU_WANT_TO_UPDATE_YOUR_PROFILE = obj.ARE_YOU_SURE_YOU_WANT_TO_UPDATE_YOUR_PROFILE;
    CONTEST_WON = obj.CONTEST_WON;
    TOTAL_CONTESTS = obj.TOTAL_CONTESTS;
    SERIES = obj.SERIES;
    SOMETHING_ERROR = obj.SOMETHING_ERROR;
    CHOOSE_AVATAR = obj.CHOOSE_AVATAR;
}

function translateNotificationData() {
    let obj = transtale('notification');
    DONT_HAVE_ANY = obj.DONT_HAVE_ANY;
    NOTIFICATION_YET = obj.NOTIFICATION_YET;
    GO_BACK_TO_LOBBY = obj.GO_BACK_TO_LOBBY;
}

function translateStaticPagesData() {
    let obj = transtale('static_pages');
    TEST = obj.TEST;
    T20 = obj.T20;
    ODI = obj.ODI;
    NO_SCORING_RULES = obj.NO_SCORING_RULES;
    ABOUT_US_TITLE = obj.ABOUT_US_TITLE;
    FAQ_TITLE = obj.FAQ_TITLE;
    TANDC_TITLE = obj.TANDC_TITLE;
    PRIVACY_POLICY_TITLE = obj.PRIVACY_POLICY_TITLE;
    Filters = obj.Filters;
    I_hereby_confirm = obj.I_hereby_confirm;
    and_I_am_not_a = obj.and_I_am_not_a;
    Oops = obj.Oops;
    Looks_like_you_have = obj.Looks_like_you_have;
    Lobby = obj.Lobby;
    Go_Back_to_Lobby = obj.Go_Back_to_Lobby;
    BY_JOINING_THIS_CONTEST = obj.BY_JOINING_THIS_CONTEST;
    AGREE_TO_CONTACTED_BY = obj.AGREE_TO_CONTACTED_BY;
    AND_THEIR_PARTNERS = obj.AND_THEIR_PARTNERS;
    WE_WHOULD_LOVE = obj.WE_WHOULD_LOVE;
    FOLLOW_US = obj.FOLLOW_US;
}

function translateContestListingData() {
    let obj = transtale('contest_listing');
    Entry_for_the_contest = obj.Entry_for_the_contest;
    Contest = obj.Contest;
    Please_Login_Signup_First = obj.Please_Login_Signup_First;
    Please_enter_valid_percentage_value = obj.Please_enter_valid_percentage_value;
    Please_enter_percentage_value_less_100 = obj.Please_enter_percentage_value_less_100;
    Your_lineup_will_be_reset = obj.Your_lineup_will_be_reset;
    Please_enter_a_valid_league_code = obj.Please_enter_a_valid_league_code;
    How_to_Play = obj.How_to_Play;
    Join_Private_League = obj.Join_Private_League;
    Enter_a_League_Code_to_Join = obj.Enter_a_League_Code_to_Join;
    Join_private_leagues = obj.Join_private_leagues;
    FANTASY_SPORTS = obj.FANTASY_SPORTS;
    Average = obj.Average;
    Season = obj.Season;
    Last_5_games = obj.Last_5_games;
    Graph_data_no_available = obj.Graph_data_no_available;
    NO_DATA_FOUND = obj.NO_DATA_FOUND;
    Want_the_best_experience = obj.Want_the_best_experience;
    play_fantasy_on_our_Android_app = obj.play_fantasy_on_our_Android_app;
    INSTALL = obj.INSTALL;
    CREATE_YOUR_TEAM = obj.CREATE_YOUR_TEAM;
    PAY_AND_JOIN = obj.PAY_AND_JOIN;
    AUTHORISED_TO_USE = obj.AUTHORISED_TO_USE;
    WINNING_BAL_TO_JOIN = obj.WINNING_BAL_TO_JOIN;
    BONUS_ALLOWED = obj.BONUS_ALLOWED;
    GO_TO = obj.GO_TO;
    TC = obj.TC;
    AMOUNT_TO_ADD = obj.AMOUNT_TO_ADD;
    FILTER_MORE_OPTION_TEXT = obj.FILTER_MORE_OPTION_TEXT;
    NO_DATA_TO_SHOW = obj.NO_DATA_TO_SHOW;
}

function translateItemData() {
    let obj = transtale('item_details');
    Hurry_Only = obj.Hurry_Only;
    left_in_stock = obj.left_in_stock;
    Redeem_NOW = obj.Redeem_NOW;
    Product_Description = obj.Product_Description;
    Coins_Range = obj.Coins_Range;
}

function translateReferralFriend() {
    let obj = transtale('referral_friend');
    CONGRATULATIONS = obj.CONGRATULATIONS;
    YOU_GOT_ = obj.YOU_GOT_;
    BONUS_CASH_ON_SIGNINGUP_REFER_CODE = obj.BONUS_CASH_ON_SIGNINGUP_REFER_CODE;
    BONUS_CASH_ON_SETTING_REF_CODE = obj.BONUS_CASH_ON_SETTING_REF_CODE;
    EDIT_REFER = obj.EDIT_REFER;
    CODE = obj.CODE;
    INVITE_YOUR_FRIEND_BIG_REWARD = obj.INVITE_YOUR_FRIEND_BIG_REWARD;
    BONUS_CASH_LOWER = obj.BONUS_CASH_LOWER;
    REAL_CASH_LOWER = obj.REAL_CASH_LOWER;
    COIN_CASH_LOWER = obj.COIN_CASH_LOWER;
    ON_TRY_COOL_REF_CODE = obj.ON_TRY_COOL_REF_CODE;
    REF_CODE = obj.REF_CODE;
    I_DONT_WANT_TO_EDIT = obj.I_DONT_WANT_TO_EDIT;
    YOU_WONT_TO_ABLE_EDIT_THIS_CODE_AGAIN = obj.YOU_WONT_TO_ABLE_EDIT_THIS_CODE_AGAIN;
    FRIENDS_SIGNUP = obj.FRIENDS_SIGNUP;
    YOU_GET = obj.YOU_GET;
    YOUR_FRIEND_GETS = obj.YOUR_FRIEND_GETS;
    ON_FRIEND_DEPOSIT = obj.ON_FRIEND_DEPOSIT;
    OF_YOU = obj.OF_YOU;
    FRIEND_DEPOSIT_MAXIMUM = obj.FRIEND_DEPOSIT_MAXIMUM;
    UPTO = obj.UPTO;
    LOYALITY_REWARDS = obj.LOYALITY_REWARDS;
    FTH_REF = obj.FTH_REF;
    TEN_REF = obj.TEN_REF;
    FIF_REF = obj.FIF_REF;
    GOT_IT = obj.GOT_IT;
    REFERRAL_SYSTEM = obj.REFERRAL_SYSTEM;
    ON_SIGN_UP = obj.ON_SIGN_UP;
    MORE_REFERRALS = obj.MORE_REFERRALS;
    ON = obj.ON;
    ERNED_FIFITH_REF = obj.ERNED_FIFITH_REF;
    ERNED_TEHTH_REF = obj.ERNED_TEHTH_REF;
    ERNED_FIFITHEEN_REF = obj.ERNED_FIFITHEEN_REF;
    FRIEND_DEPOSIT = obj.FRIEND_DEPOSIT;
    ACHIEVEMENTS = obj.ACHIEVEMENTS;
    OF_YOUR_FRIEND_DEPOSIT = obj.OF_YOUR_FRIEND_DEPOSIT;
    OF_YOUR_FRIEND_DEPOSIT_MAXIMUM = obj.OF_YOUR_FRIEND_DEPOSIT_MAXIMUM;
    REFER_A_FRIEND = obj.REFER_A_FRIEND;
    REFER_A_FRIEND_C = obj.REFER_A_FRIEND_C;
    REAL_CASE_ON_YOUR_FRIEND_SIGN_UP = obj.REAL_CASE_ON_YOUR_FRIEND_SIGN_UP;
    INVITE_FRIEND_WIN_REWARD = obj.INVITE_FRIEND_WIN_REWARD;
    REFER_NOW = obj.REFER_NOW;
    EDIT_YOUR_CODE_BONUS = obj.EDIT_YOUR_CODE_BONUS;
    IT_PAY_TO_HAVE = obj.IT_PAY_TO_HAVE;
    YOU_WILL_EARN_ON_EACH_NEW_SIGN_UP = obj.YOU_WILL_EARN_ON_EACH_NEW_SIGN_UP;
    INVALID_ALPHA_NUMERIC_KEY = obj.INVALID_ALPHA_NUMERIC_KEY;
    TOTAL_EARNING = obj.TOTAL_EARNING;
    LOAD_MORE_RESULTS = obj.LOAD_MORE_RESULTS;
    HOW_IT_WORKS = obj.HOW_IT_WORKS;
    VIEW_LEADEBOARD=obj.VIEW_LEADEBOARD;
    TOTAL_REFERAL=obj.TOTAL_REFERAL;
    REFERAL_LEADERBOARD=obj.REFERAL_LEADERBOARD;
    REFER_MORE_TEXT=obj.REFER_MORE_TEXT;
    GET_EXCITING_REWARDS_ON_EVERY_FRIEND_SIGNUP=obj.GET_EXCITING_REWARDS_ON_EVERY_FRIEND_SIGNUP;

}
function translateEsport() {
    let obj = transtale('esport');
    DONT_SHOW_ME_AGAIN = obj.DONT_SHOW_ME_AGAIN;
    CUSTOMIZE_REFERRAL_CODE = obj.CUSTOMIZE_REFERRAL_CODE;
}
function translateAffiliate() {
    let obj = transtale('affiliate');
    BECOME_AFFILIATE = obj.BECOME_AFFILIATE;
    AFFILIATE_PROGRAM = obj.AFFILIATE_PROGRAM;
    AFFILIATE_TAGLINE1 = obj.AFFILIATE_TAGLINE1;
    AFFILIATE_TAGLINE2 = obj.AFFILIATE_TAGLINE2;
    INDUSTRY_LEADING = obj.INDUSTRY_LEADING;
    INDUSTRY_LEADING_TAGLINE = obj.INDUSTRY_LEADING_TAGLINE;
    PROMOTION_EASY = obj.PROMOTION_EASY;
    PROMOTION_EASY_TAGLINE = obj.PROMOTION_EASY_TAGLINE;
    POWERFUL_TRACKING = obj.POWERFUL_TRACKING;
    POWERFUL_TRACKING_TAGLINE = obj.POWERFUL_TRACKING_TAGLINE;
    DETAILS = obj.DETAILS;
    USER = obj.USER;
    AFF_AMOUNT = obj.AFF_AMOUNT;
    COMMISION = obj.COMMISION;
    SIGNUP = obj.SIGNUP;
    AMOUNT_DEPOSITED = obj.AMOUNT_DEPOSITED;
    BONUS_ON = obj.BONUS_ON;
    COMMISION_ON = obj.COMMISION_ON;
    TOTAL_SIGNUP = obj.TOTAL_SIGNUP;
    DEPOSITED_AMOUNT = obj.DEPOSITED_AMOUNT;
    COMMISION_TILL_DATE = obj.COMMISION_TILL_DATE;
    COMMISION_DUE = obj.COMMISION_DUE;
    DEPOSITE = obj.DEPOSITE;
    REQ_PENDING = obj.REQ_PENDING;
    REQ_CANCELED = obj.REQ_CANCELED;
}
function translateCoinEntry() {
    let obj = transtale('coin_entry');
    ADD_COIN_AND_JOIN_CONTEST = obj.ADD_COIN_AND_JOIN_CONTEST;
    PAYABLE_COINS_BAL = obj.PAYABLE_COINS_BAL;
}
function translateCoinModule() {
    let obj = transtale('coin_module');
    BUY_COINS = obj.BUY_COINS;
    AMOUNT_DEDUCTION_MSG = obj.AMOUNT_DEDUCTION_MSG;
    BUY_CONFIRMATION = obj.BUY_CONFIRMATION;
    BUY = obj.BUY;
}
function translateTeamCompare() {
    let obj = transtale('team_compare');
    SEE = obj.SEE;
    ON_FIELDVIEW = obj.ON_FIELDVIEW;
    OPP_LEAD_BY = obj.OPP_LEAD_BY;
    YOU_LEAD_BY = obj.YOU_LEAD_BY;
    COMMON_PLAYERS = obj.COMMON_PLAYERS;
    OPP_CVC_LEAD_BY = obj.OPP_CVC_LEAD_BY;
    YOU_CVC_LEAD_BY = obj.YOU_CVC_LEAD_BY;
    ALL_PLAYERS = obj.ALL_PLAYERS;
    TOTAL_POINTS = obj.TOTAL_POINTS;
    YOU_CANT_COMPARE_YOU_OWN_TEAMS = obj.YOU_CANT_COMPARE_YOU_OWN_TEAMS;
    COMPARE_TEAMS = obj.COMPARE_TEAMS;
    PTS1 = obj.PTS1;
}
function translateReverseFantasy() {
    let obj = transtale('reverse_fantasy');
    REVERSE_FANTASY = obj.REVERSE_FANTASY;
    REVERSE_FANTASY_JUST_OPP = obj.REVERSE_FANTASY_JUST_OPP;
    REVERSE_FANTASY_TEAMS = obj.REVERSE_FANTASY_TEAMS;
    FEEL_THE_THRILL_OF_REVERSE = obj.FEEL_THE_THRILL_OF_REVERSE;
    REVERSE_ALERT_MSG = obj.REVERSE_ALERT_MSG;
    OKAY = obj.OKAY;
    WHATS_REVERSE_FANTASY = obj.WHATS_REVERSE_FANTASY;
    WRF_HEAD1 = obj.WRF_HEAD1;
    WRF_DESC1 = obj.WRF_DESC1;
    WRF_HEAD2 = obj.WRF_HEAD2;
    WRF_DESC2 = obj.WRF_DESC2;
    WRF_HEAD3 = obj.WRF_HEAD3;
    WRF_DESC3 = obj.WRF_DESC3;
    SEE_REVERSE_FANTASY = obj.SEE_REVERSE_FANTASY;
    RULES = obj.RULES;
    RF_TOOLTIP_TEXT = obj.RF_TOOLTIP_TEXT;
    YOU_CAN_CREATE_ONLY_10TEAMS = obj.YOU_CAN_CREATE_ONLY_10TEAMS;
    CLASSIC_FANTASY = obj.CLASSIC_FANTASY;
}
